import React, { useState, useEffect } from "react";
import axios from "axios";
import validator from "validator";
import Swal from "sweetalert2";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import Loading from "../loading/Loading";
import LoadingSkeleton from "../loading/LoadingSkeleton";

import SelectemCust from "../valueChain/emCust";

import MyModal from "./MyModal";
import { logout, login, isLogin, getToken } from "../../auth";

import { refreshToken } from "../../refreshToken";
import DataTable from "react-data-table-component";

const Home = () => {
  const [mso, setMso] = useState([]);
  const [cust, setCust] = useState([]);
  const [custMSO, setCustMSO] = useState([]);
  /// create
  const [formErrors, setFormErrors] = useState([]);
  const [group_id, setGroup_id] = useState(null);
  const [group_name, setGroup_name] = useState("");
  const [group_remark, setGroup_remark] = useState("");
  const [group_status, setGroup_status] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modelTitle, setModelTitle] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const token = localStorage.getItem("token");

  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  //// to see group customer
  const [myModal_group_id, setMyModal_group_id] = useState(null);
  const [myModal_group_name, setMyModal_group_name] = useState(null);
  const [showMyModal, setShowMyModal] = useState(false);
  const handleMyShow = () => setShowMyModal(true);
  const handleMyClose = () => setShowMyModal(false);

  useEffect(() => {
    getMSO();
  }, []);

  const getMSO = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/mso`, {
        headers: {
          // 'Authorization': `${process.env.REACT_APP_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.status)
        setMso(res.data.data);
        setFilteredData(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log(error)
        setIsLoading(false);
        Swal.fire({
          title: error.message,
          text: "Please try again later",
          icon: "error",
        });
      });
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "5%",
    },

    {
      name: "MSO Code",
      selector: (row) => row.group_code,
      cell: (row) => (
        <div
          className="mouse-pointer text-color-link"
          onClick={() =>
            clickMSOName(row.group_id, `${row.group_code} / ${row.group_name}`)
          }
        >
          {row.group_code}
        </div>
      ),
      width: "10%",
      sortable: true,
    },
    {
      name: "MSO Name",
      selector: (row) => row.group_name,
      cell: (row) => (
        <div
          className="mouse-pointer text-color-link"
          onClick={() =>
            clickMSOName(row.group_id, `${row.group_code} / ${row.group_name}`)
          }
        >
          {row.group_name}
        </div>
      ),
      width: "15%",
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.group_status,
      cell: (row) => (row.group_status === "A" ? "Active" : "InActive"),
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => row.jobSendDate,
      cell: (row) => (
        <i
          onClick={() =>
            model(
              row.group_id,
              setGroup_name(row.group_name),
              setGroup_remark(row.group_name),
              setGroup_status(row.group_status)
            )
          }
          className="ri-edit-circle-fill"
          style={{ fontSize: "22px", cursor: "pointer" }}
        ></i>
      ),
    },
    {
      name: "Create Date",
      selector: (row) => row.group_CreateDate,
      sortable: true,
    },
  ];

  const addObjectToSetCust = (selectedOption) => {
    console.table(selectedOption);
    const exists = cust.some((item) => item.value === selectedOption.value);
    if (!exists) {
      setCust([...cust, selectedOption].sort((a, b) => a.label - b.label));
    } else {
      console.log("Selected option already exists in cust");
      Swal.fire({
        title: "Customer already exists",
        text: "Selected option already exists in cust",
        icon: "warning",
      });
    }
    return selectedOption;
  };

  // const addObjectToSetCust = (selectedOption) => {

  //   console.log(selectedOption)
  //   setCust([...cust, selectedOption]);
  //   return selectedOption;
  // };

  const deleteCust = (valueToDelete) => {
    const updatedSetCust = cust.filter(
      (cust) => cust["value"] !== valueToDelete
    );
    setCust(updatedSetCust);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!validator.isLength(group_name, { min: 1 })) {
      errors.group_name = "MSO name is required";
    }

    if (!group_id) {
      if (cust.length === 0) {
        errors.cust = "Customer  is required";
      }
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (group_id === null) {
        const data = {
          group_name: group_name,
          group_remark: group_remark,
          group_status: group_status,
          cust: cust,
        };
        createMSO(data);
      } else {
        const data = {
          group_name: group_name,
          group_remark: group_remark,
          group_status: group_status,
        };
        updateMSO(data);
      }
    }
  };

  const createMSO = (data) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/mso`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success!",
          text: res.data.messages["success"],
          icon: "success",
          confirmButtonText: "OK",
        });
        getMSO();
        setShow(false);
        setCust([]);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: error.message,
          text: "Please try again later",
          icon: "error",
        });
      });
  };

  const updateMSO = (data) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/mso/${group_id}`, data, {
        headers: {
          // 'Authorization': `${process.env.REACT_APP_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        Swal.fire({
          title: "Success!",
          text: res.data["message"],
          icon: "success",
          confirmButtonText: "OK",
        });
        getMSO();
        setShow(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: error.message,
          text: "Please try again later",
          icon: "error",
        });
      });
  };

  const model = (e) => {
    setCustMSO([]);
    setCust([]);
    console.log(e);
    //// create new MSO
    if (e === null) {
      setGroup_name("");
      setGroup_remark("");
      setGroup_id(null);
      setModelTitle("Create New MSO Customer ");
      setShow(true);
    } else {
      setCust([]);
      setModelTitle("MSO Detail Customer");
      setShow(true);
      setGroup_id(e);
      // getMSOCust()

      getMSOCustList(e);
    }
  };

  const getMSOCustList = (dataGroup_id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/mso-detail-cust/${dataGroup_id}`, {
        headers: {
          // 'Authorization': `${process.env.REACT_APP_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data);
          setCustMSO(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCustMSO = async (cust_id, cust_statusUpdate) => {
    const status = cust_statusUpdate === "A" ? "D" : "A";
    const data = {
      cust_status: status,
    };
    console.log(cust_id);
    console.log(data);

    try {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/value-chain-update/${cust_id}`,
          data,
          {
            headers: {
              // 'Authorization': `${process.env.REACT_APP_API_KEY}`,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          const updatedCustMSO = custMSO.map((item) => {
            if (item["cust_id"] === cust_id) {
              return { ...item, cust_status: status };
            }
            return item;
          });

          setCustMSO(updatedCustMSO);
        });
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  const addCustMSO = () => {
    const data = {
      cust: cust,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/mso-add-cust/${group_id}`, data, {
        headers: {
          // 'Authorization': `${process.env.REACT_APP_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Success!",
          text: res.data.messages["success"],
          icon: "success",
          confirmButtonText: "OK",
        });
        setCust([]);
        getMSOCustList(group_id);
        // getMSO()
        // setShow(false)
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: error.message,
          text: "Please try again later",
          icon: "error",
        });
      });
  };

  const clickMSOName = (group_id, group_name) => {
    // setGroupDetailModel(true)
    // setGroupDetailModel_id(id)
    // alert(id)
    setMyModal_group_name(group_name);
    setMyModal_group_id(group_id);
    setShowMyModal(true);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setQuery(query);
    const filtered = mso.filter((item) => {
      return (
        item.group_code.toLowerCase().includes(query.toLowerCase()) ||
        item.group_name.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  //   const handleSearchChange = (e) => {
  //     const searchQuery = e.target.value;
  //     setQuery(searchQuery);
  //     const filteredResults = mso.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     );

  //     setFilteredData(filteredResults);
  //   };

  if (isLoading) {
    return (
      <>
        <Loading />
        <LoadingSkeleton />
      </>
    );
  }

  return (
    <>
      <MyModal
        group_id={myModal_group_id}
        group_name={myModal_group_name}
        showModal={showMyModal}
        handleClose={handleMyClose}
      />

      <section className="dashboard">
        <div className="pagetitle">
          <h1>MSO Customer</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Value Chain</a>
              </li>
              <li className="breadcrumb-item active">MSO Group</li>
            </ol>
          </nav>
        </div>

        <div className="col-12">
          <div className="card info-card sales-card">
            <div className="row pt-3 p-3 text-end ">
              <div className="col-md-9"> </div>
              <div className="col-md-2 text-end  ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search...."
                  value={query}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="col-md-1">
                <div
                  className="  text-end cursor-pointer "
                  onClick={() => model(null)}
                >
                  <div className="icon">
                    <i
                      className="bx bxs-folder-plus"
                      style={{ fontSize: "40px" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <h3 className="card-title">MSO Data </h3>
              <DataTable
                columns={columns}
                data={filteredData}
                // data={mso}
                pagination
                fixedHeader
                // progressPending={pending}
                // progressComponent={<Loading />}
                sortField="DueDate"
                sortDirection="desc"
              />

              <hr />
              <div className="table-responsive d-none">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">
                        MSO Code <br />
                        รหัส MSO
                      </th>
                      <th scope="col">
                        MSO Name <br /> ชื่อกลุ่มธุรกิจ{" "}
                      </th>
                      <th scope="col">
                        Status <br /> สถานะ
                      </th>
                      <th scope="col">
                        Action <br /> แก้ไข
                      </th>
                      <th scope="col">
                        Create Date <br /> วันบันทึกข้อมูล
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>{item["group_code"]}</td>
                        <td>
                          <div
                            className="text-dark-blue  mouse-pointer"
                            onClick={() =>
                              clickMSOName(
                                item["group_id"],
                                item["group_code"] + " / " + item["group_name"]
                              )
                            }
                          >
                            {item["group_name"]}{" "}
                          </div>
                        </td>
                        <td>
                          {item["group_status"] === "A"
                            ? "Actived"
                            : "InActived"}
                        </td>
                        <td>
                          <i
                            onClick={() =>
                              model(
                                item["group_id"],
                                setGroup_name(item["group_name"]),
                                setGroup_remark(item["group_name"]),
                                setGroup_status(item["group_status"])
                              )
                            }
                            className="ri-edit-circle-fill"
                            style={{ fontSize: "22px", cursor: "pointer" }}
                          ></i>
                        </td>
                        <td>{item["group_CreateDate"]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Modal
          backdrop="static"
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modelTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body ">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    MSO Customer Detail
                  </button>
                </li>
                {group_id && (
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      tabindex="-1"
                    >
                      MSO Customer lists
                    </button>
                  </li>
                )}
              </ul>
              <div
                className="tab-content pt-3 p-3"
                id="myTabContent"
                style={{ minHeight: "360px" }}
              >
                <div
                  className="tab-pane fade active show"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <form className="from-control">
                    <div className="row mb-3 pt-5 d-none ">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        MSO Code
                      </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-2 col-form-label">
                        MSO Name
                      </label>
                      <div className="col-sm-10">
                        <input
                          value={group_name}
                          onChange={(e) => setGroup_name(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                        {formErrors.group_name && (
                          <div className="form-control-feedback">
                            {formErrors.group_name}
                          </div>
                        )}
                      </div>
                    </div>

                    {!group_id && (
                      <>
                        <div className="row mb-3">
                          <label className="col-sm-2 col-form-label">
                            Cus Code / Name:
                          </label>
                          <div className="col-sm-10">
                            <SelectemCust
                              onChangeType={addObjectToSetCust}
                              value=""
                            />
                            {formErrors.cust && (
                              <div className="form-control-feedback">
                                {formErrors.cust}
                              </div>
                            )}
                            <table className="table">
                              <tbody>
                                {cust.map((item, key) => (
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>{item["label"]}</td>
                                    <td>
                                      <i
                                        className="bi bi-trash-fill mouse-pointer"
                                        onClick={() =>
                                          deleteCust(item["value"])
                                        }
                                      ></i>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="row mb-3">
                      <label className="col-sm-2 col-form-label">Remark</label>
                      <div className="col-sm-10">
                        <textarea
                          value={group_remark}
                          onChange={(e) => setGroup_remark(e.target.value)}
                          rows="3"
                          className="form-control"
                        />
                      </div>
                    </div>
                    {group_id && (
                      <div className="row mb-3">
                        <label className="col-sm-2 col-form-label">
                          MSO Status
                        </label>
                        {/* <div className="col-sm-10 d-none">
                                                    <select
                                                        onChange={e => setGroup_status(e.target.value)}
                                                        value={group_status}
                                                        className="form-control">
                                                        <option value="A" {...group_status === 'A' && 'selected'} > Active </option>
                                                        <option value="I"  {...group_status === 'I' && 'selected'}> Inactive </option>
                                                    </select>
                                                </div> */}

                        <div className="col-sm-10">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="A"
                              checked={group_status === "A"}
                              onChange={() => setGroup_status("A")}
                            />
                            <label className="form-check-label">Active</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="I"
                              checked={group_status === "I"}
                              onChange={() => setGroup_status("I")}
                            />
                            <label className="form-check-label">Inactive</label>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>

                  <div className="text-center">
                    <Button
                      variant="primary"
                      className="text-right btn-lg"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="row mb-3">
                    <label className="col-form-label">Cus Code / Name:</label>
                    <div className="col-sm-12">
                      <SelectemCust
                        onChangeType={addObjectToSetCust}
                        value=""
                      />
                      {formErrors.cust && (
                        <div className="form-control-feedback">
                          {formErrors.cust}
                        </div>
                      )}
                      <table className="table ">
                        <tbody>
                          {cust.map((item, key) => (
                            <tr>
                              <td>{key + 1}</td>
                              <td>{item["label"]}</td>
                              <td>
                                <i
                                  className="bi bi-trash-fill mouse-pointer"
                                  onClick={() => deleteCust(item["value"])}
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="text-right">
                        <Button
                          variant="primary"
                          className="btn-sm"
                          onClick={addCustMSO}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  </div>

                  <table className="table ">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Customer</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {custMSO.length > 0 ? (
                        custMSO.map((item, key) => (
                          <tr key={key} className="mouse-pointer">
                            <td>{key + 1}</td>
                            <td>{item["custName"]}</td>
                            <td>
                              {item["cust_status"] === "A"
                                ? "Active"
                                : "InActive"}
                            </td>
                            <td>
                              <button
                                onClick={() =>
                                  deleteCustMSO(
                                    item["cust_id"],
                                    item["cust_status"]
                                  )
                                }
                                type="button"
                                className={`btn btn-sm ${
                                  item["cust_status"] === "A"
                                    ? "btn-success"
                                    : "  btn-danger"
                                }`}
                              >
                                <i
                                  className={`${
                                    item["cust_status"] === "A"
                                      ? "bi bi-eye-fill"
                                      : " bi bi-eye-slash-fill"
                                  }`}
                                />
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="4"
                            className=" text-center text-black g-font-size-16"
                          >
                            Data not found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
};

export default Home;
