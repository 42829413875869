import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../axiosInstance";

import validator from "validator";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";

/// compoment
// import SelectMSO from "./SelectMSO";
import SelectemCust from "./emCust";
import SelectemSaleArea from "./emSaleArea";
import SelectempSales from "./emSales";
import SelectMSO from "./SelectMSO";
// import SelectFarmingHunting from "./SelectFarmingHunting";
// import ApiSearchSelectEmCust from "./ApiSearchSelectEmCust";
// import { logout, login, isLogin, getToken } from '../../auth';
import LoadingOverLay from "../loading/LoadingOverLay";

const ValueChainInfo = ({ cust_id, customer, onUpdated }) => {
  const [group_id, setGroup_id] = useState({ value: customer["group_id"] });
  const [editing_MSO, setEditing_MSO] = useState(false);

  const [editingCust_subContractor, setEditingCust_subContractor] =
    useState(false);
  const [editingCust_painDealer, setEditingCust_painDealer] = useState(false);

  const [cust_idERP, setCust_idERP] = useState();
  const [cust_province, setCust_province] = useState({
    value: customer["cust_province"],
  });
  // const [cust_salePerson, setCust_salePerson] = useState({ value: customer['cust_salePerson'] });
  const [cust_salePerson, setCust_salePerson] = useState({
    value: customer["cust_salePerson"] || null,
  });

  const [msoGroup_id, setMSOGroup_id] = useState();

  const [cust_group, setCust_group] = useState(customer["cust_group"]);
  const [cust_centralBill, setCust_centralBill] = useState(
    customer["cust_centralBill"]
  );
  const [cust_subContractor, setCust_subContractor] = useState({
    value: customer["cust_subContractor"],
  });
  const [cust_subContractorSystem, setCust_subContractorSystem] = useState(
    customer["cust_subContractorSystem"] || "NONE"
  );
  const [cust_painDealer, setCust_painDealer] = useState({
    value: customer["cust_painDealer"],
  });
  const [cust_HF, setCust_HF] = useState(customer["cust_HF"]); ///Farming or Hunting
  const [cust_HFStatus, setCust_HFStatus] = useState(customer["cust_HFStatus"]); ///Farming or Hunting ---> status
  const [cust_HFSystem, setCust_HFSystem] = useState(customer["cust_HFSystem"]); ///Farming  ---> status
  const [cust_segment, setCust_segment] = useState(customer["cust_segment"]); ///Farming  ---> Customer Segment
  const [cust_remark, setCust_remark] = useState(customer["cust_remark"]);
  // const [farmingOrHunting, setFarmingOrHunting] = useState(customer['cust_HF']); Farming
  const [farmingOrHunting, setFarmingOrHunting] = useState("Farming");
  const [formErrors, setFormErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [paintSystemData, setPaintSystemData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [farmingData, setFarmingData] = useState([]);

  useEffect(() => {
    // console.log(cust_subContractor)
    // console.log(customer)
    // setGroup_id(customer['group_id'])
    // console.log(customer['group_id'])
    // console.log(customer)
    // console.log(customer['cust_salePerson'])
    getMasterData();
  }, []);

  const getMasterData = async () => {
    try {
      const [paintSystemResponse, segmentResponse, farmingResponse] =
        await Promise.all([
          axiosInstance.get("/setup-dbpaintsystem"),
          axiosInstance.get("/setup-dbsegment"),
          axiosInstance.get("/setup-dbfarming"),
        ]);

      // console.log(paintSystemResponse.data);
      // console.log(segmentResponse.data);
      // console.log(farmingResponse.data);

      setPaintSystemData(paintSystemResponse.data);
      setSegmentData(segmentResponse.data);
      setFarmingData(farmingResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      //   setLoading(false);
    }
  };

  ///MSO GROUP
  const handleSelectMSO = (selectedOption) => {
    console.log(selectedOption);
    setGroup_id(selectedOption);
    return selectedOption.value;
  };

  ///Customer Name
  const handleSelectCust = (selectedOption) => {
    setCust_idERP(selectedOption);
    console.log(selectedOption);
    return selectedOption;
  };

  ///Customer Name -> Cust_subContractor
  const handleSelectSubContractor = (selectedOption) => {
    setCust_subContractor(selectedOption);
    // console.log(selectedOption)
    return selectedOption;
  };
  ///Customer Name -> Cust_subContractor
  const handleSelectPainDealer = (selectedOption) => {
    setCust_painDealer(selectedOption);
    // console.log(selectedOption)
    return selectedOption;
  };

  ///Province
  const handleSelectSaleArea = (selectedOption) => {
    setCust_province(selectedOption);
    // console.log(selectedOption.value)
    return selectedOption;
  };

  ///Sales Person
  const handleSelectSale = (selectedOption) => {
    setCust_salePerson(selectedOption);
    console.log(selectedOption);
    return selectedOption;
  };

  ///  Farming or Hunting
  const handleClick = (e) => {
    setCust_HFStatus({ value: "" });

    if (e.target.value === "Hunting") {
      setCust_segment(null);
      setCust_HFSystem(null);
    }
    setCust_HF(e.target.value);
    setFarmingOrHunting(e.target.value);
  };

  /// FarmingHunting Status
  const handleFarmingHuntingStatus = (selectedOption) => {
    setCust_HFStatus(selectedOption);
    console.log(selectedOption);
    return selectedOption;
  };

  const handleUpdated = (status) => {
    onUpdated(status);
    return status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!cust_province.value) {
      errors.cust_province = "Province Name is required";
    }
    if (!cust_salePerson.value) {
      errors.cust_salePerson = "Sales Person is required";
    }
    if (!cust_group) {
      errors.cust_group = "Customer Group is required";
    }
    if (!cust_centralBill) {
      errors.cust_centralBill = "Central Billing is required";
    }
    if (!cust_subContractor) {
      errors.cust_subContractor = "Sub Contractor is required";
    }
    if (!cust_subContractorSystem) {
      errors.cust_subContractorSystem = "Sub Contractor system is required";
    }

    console.log(errors);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const data = {
        group_id: group_id["value"],
        // 'cust_idERP': cust_idERP,
        cust_province: cust_province["value"],
        cust_salePerson: cust_salePerson["value"],
        cust_group: cust_group,
        cust_centralBill: cust_centralBill,
        cust_subContractor: cust_subContractor["value"],
        cust_subContractorSystem: cust_subContractorSystem,
        cust_painDealer: cust_painDealer["value"],
        // 'cust_HF': cust_HF,
        cust_HFStatus: cust_HFStatus,
        cust_HFSystem: cust_HFSystem,
        cust_segment: cust_segment,
        cust_remark: cust_remark,
      };
      // console.log(cust_id)
      // console.log(data)
      setIsLoading(true);

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/value-chain-update/${cust_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          handleUpdated(true);
          Swal.fire({
            title: "Success!",
            text: res.data["message"],
            icon: "success",
            confirmButtonText: "OK",
          });
          // setIsLoading(false)
          setEditingCust_painDealer(false);
          setEditingCust_subContractor(false);
          setEditing_MSO(false);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: error.message,
            text: "Please try again later",
            icon: "error",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      Swal.fire({
        title: "บันทึกรายการไม่สำเร็จ",
        text: "โปรดตรวจสอบข้อมูลให้ถูกต้อง",
        icon: "error",
      });
    }
  };

  return (
    <>
      {isLoading && <LoadingOverLay />}

      <form>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Customer Code/Name
            <br /> รหัส/ชื่อลูกค้า
          </label>
          <div className="col-sm-9 col-form-label">
            {customer["CustCode"] + " / " + customer["CustShortName"]}
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            MSO Code/Name
            <br />
            กลุ่มธุรกิจ
          </label>
          <div className="col-sm-9 col-form-label">
            {customer["group_code"] ? (
              <>{customer["group_code"] + " / " + customer["group_name"]} </>
            ) : (
              <>None-MSO Customer </>
            )}

            {editing_MSO ? (
              <SelectMSO onChangeType={handleSelectMSO} value={group_id} />
            ) : (
              <span onClick={() => setEditing_MSO(true)}>
                <i className="ri-edit-circle-fill"></i>
              </span>
            )}
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Province
            <br /> จังหวัด
          </label>
          <div className="col-sm-9">
            <SelectemSaleArea
              onChangeType={handleSelectSaleArea}
              value={cust_province}
            />
            {formErrors.cust_province && (
              <div className="form-control-feedback">
                {formErrors.cust_province}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Sales Person
            <br /> พนักงานขาย
          </label>
          <div className="col-sm-9">
            <SelectempSales
              onChangeType={handleSelectSale}
              value={cust_salePerson}
            />
            {formErrors.cust_salePerson && (
              <div className="form-control-feedback">
                {formErrors.cust_salePerson}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Customer Group
            <br /> กลุ่มลูกค้า
          </label>
          <div className="col-sm-9">
            <select
              onChange={(e) => setCust_group(e.target.value)}
              value={cust_group}
              className="form-select"
            >
              <option value=""> - Choose - </option>
              <option value="IB">IB อู่อิสระ</option>
              <option value="OE">OE ศูนย์บริการ</option>
            </select>
            {formErrors.cust_group && (
              <div className="form-control-feedback">
                {formErrors.cust_group}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Central Billing
            <br /> ซื้อผ่าน Central Billing
          </label>
          <div className="col-sm-9">
            <select
              onChange={(e) => setCust_centralBill(e.target.value)}
              value={cust_centralBill}
              className="form-select"
            >
              <option value=""> - Choose -</option>
              <option value="YES">YES</option>
              <option value="NO">NO</option>
            </select>
            {formErrors.cust_centralBill && (
              <div className="form-control-feedback">
                {formErrors.cust_centralBill}
              </div>
            )}
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Sub Contractor
            <br /> ซื้อผ่าน Sub Contractor
          </label>
          <div className="col-sm-9">
            {customer["cust_subContractorName"]}
            {editingCust_subContractor ? (
              <SelectemCust
                onChangeType={handleSelectSubContractor}
                value={cust_subContractor}
              />
            ) : (
              <span onClick={() => setEditingCust_subContractor(true)}>
                <i className="ri-edit-circle-fill"></i>
              </span>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Sub Contractor System
            <br /> การใช้งานสีของ Sub Contractor
          </label>
          <div className="col-sm-9">
            <select
              onChange={(e) => setCust_subContractorSystem(e.target.value)}
              value={cust_subContractorSystem}
              className="form-select"
            >
              <option value=""> - Choose - </option>
              <option value="NONE">NONE</option>
              <option value="GL Full">GL Full</option>
              <option value="BRC Full">BRC Full</option>
              <option value="Non Full">Non Full</option>
              <option value="Competitor ">Competitor </option>
            </select>
            {formErrors.cust_subContractorSystem && (
              <div className="form-control-feedback">
                {formErrors.cust_subContractorSystem}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Paint Dealer
            <br /> ซื้อผ่าน ตัวแทนจำหน่าย
          </label>
          <div className="col-sm-9">
            {customer["cust_painDealerName"]}
            {editingCust_painDealer ? (
              <SelectemCust
                onChangeType={handleSelectPainDealer}
                value={cust_painDealer}
              />
            ) : (
              <span onClick={() => setEditingCust_painDealer(true)}>
                <i className="ri-edit-circle-fill"></i>
              </span>
            )}
          </div>
        </div>
        <hr />
        {/* <div className="row mb-3 pt-4">
                    <label className="col-sm-3 col-form-label">Farming or Hunting</label>
                    <div className="col-sm-9">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="gridRadios"
                                value="Farming"
                                onClick={e => handleClick(e)}
                                defaultChecked={farmingOrHunting === "Farming"}

                            />
                            <label className="form-check-label" >
                                Farming

                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="gridRadios"
                                value="Hunting"
                                onClick={e => handleClick(e)}
                                defaultChecked={farmingOrHunting === "Hunting"}
                            />
                            <label className="form-check-label" >
                                Hunting
                            </label>
                        </div>
                        {formErrors.cust_HF && <div className="form-control-feedback">{formErrors.cust_HF}</div>}
                    </div>
                </div> */}

        {farmingOrHunting && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              {" "}
              {farmingOrHunting} Status
              <br /> สถานะ
            </label>
            <div className="col-sm-9">
              <select
                onChange={(e) => setCust_HFStatus(e.target.value)}
                value={cust_HFStatus}
                className="form-select"
              >
                <option value=""> - Choose - </option>
                {farmingData.map((farming, index) => (
                  <option key={index} value={farming.farmingID}>
                    {farming.farmingName}
                  </option>
                ))}
              </select>
              <div className="form-control-feedback">
                *สำหรับ update ข้อมูลเท่านั้น
              </div>

              {formErrors.cust_HFStatus && (
                <div className="form-control-feedback">
                  {formErrors.cust_HFStatus}
                </div>
              )}
            </div>
          </div>
        )}

        {farmingOrHunting === "Farming" && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Paint System
              <br />
              การใช้งานสี
            </label>
            <div className="col-sm-9">
              <select
                onChange={(e) => setCust_HFSystem(e.target.value)}
                value={cust_HFSystem}
                className="form-select"
              >
                <option value=""> - Choose - </option>
                {/* <option value="GL Full">GL Full</option>
                <option value="BRC Full">BRC Full</option>
                <option value="Mix with competitor brand">
                  Mix with competitor brand
                </option> */}

                {paintSystemData.map((paintSystem, index) => (
                  <option key={index} value={paintSystem.paintID}>
                    {paintSystem.paintName}
                  </option>
                ))}
              </select>
              {formErrors.cust_HFSystem && (
                <div className="form-control-feedback">
                  {formErrors.cust_HFSystem}
                </div>
              )}
            </div>
          </div>
        )}
        {farmingOrHunting === "Farming" && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Customer Segment
              <br />
              การแบ่งกลุ่มลูกค้า
            </label>
            <div className="col-sm-9">
              <select
                onChange={(e) => setCust_segment(e.target.value)}
                value={cust_segment}
                className="form-select"
              >
                <option value=""> - Choose -</option>
                {/* <option value="LEGEND">LEGEND</option>
                <option value="DIAMOND">DIAMOND</option>
                <option value="PLATINUM (GL FULL)">PLATINUM (GL FULL)</option>
                <option value="PLATINUM (BRC FULL)">PLATINUM (BRC FULL)</option>
                <option value="GOLD">GOLD</option>
                <option value="NONE">GONONELD</option> */}

                {segmentData.map((segment, index) => (
                  <option key={index} value={segment.segmentID}>
                    {segment.segmentName}
                  </option>
                ))}
              </select>
              {formErrors.cust_segment && (
                <div className="form-control-feedback">
                  {formErrors.cust_segment}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Remark
            <br /> หมายเหตุ
          </label>
          <div className="col-sm-9">
            <textarea
              onChange={(e) => setCust_remark(e.target.value)}
              value={cust_remark}
              rows="3"
              className="form-control"
            />
          </div>
        </div>

        <div className="row mb-3">
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </div>
      </form>
    </>
  );
};

export default ValueChainInfo;
