import React, { useState, useEffect } from "react";
import axios from "axios";
import validator from "validator";
import Swal from "sweetalert2";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

/// compoment
import SelectMSO from "./SelectMSO";
// import SelectemCust from "./emCust";
import SelectemSaleArea from "./emSaleArea";
import SelectempSales from "./emSales";
import SelectFarmingHunting from "./SelectFarmingHunting";

import ApiSearchSelectEmCust from './ApiSearchSelectEmCust';


// import { logout, login, isLogin, getToken } from '../../auth';


const NoneMSOValueChainCreate = () => {
    const navigate = useNavigate();

    const [group_id, setGroup_id] = useState('')
    const [cust_idERP, setCust_idERP] = useState('');
    const [cust_province, setCust_province] = useState('');
    const [cust_salePerson, setCust_salePerson] = useState('');
    const [cust_group, setCust_group] = useState('');
    const [cust_centralBill, setCust_centralBill] = useState('');
    const [cust_subContractor, setCust_subContractor] = useState('');
    const [cust_subContractorSystem, setCust_subContractorSystem] = useState('');
    const [cust_painDealer, setCust_painDealer] = useState('');
    const [cust_HF, setCust_HF] = useState(''); ///Farming or Hunting
    const [cust_HFStatus, setCust_HFStatus] = useState({ value: '', label: '' }); ///Farming or Hunting ---> status
    const [cust_HFSystem, setCust_HFSystem] = useState(''); ///Farming  ---> status
    const [cust_segment, setCust_segment] = useState(''); ///Farming  ---> Customer Segment
    const [cust_remark, setCust_remark] = useState('');

    const [farmingOrHunting, setFarmingOrHunting] = useState('');
    const [formErrors, setFormErrors] = useState([])



    ///MSO GROUP
    const handleSelectMSO = (selectedOption) => {
        setGroup_id(selectedOption.value)
        console.log(selectedOption)
        return selectedOption;
    };

    ///Customer Name
    const handleSelectCust = (selectedOption) => {
        setCust_idERP(selectedOption.value)
        console.log(selectedOption)
        return selectedOption;
    };
    ///Customer Name -> Cust_subContractor
    const handleSelectSubContractor = (selectedOption) => {
        setCust_subContractor(selectedOption.value)
        console.log(selectedOption)
        return selectedOption;
    };
    ///Customer Name -> Cust_subContractor
    const handleSelectPainDealer = (selectedOption) => {
        setCust_painDealer(selectedOption.value)
        console.log(selectedOption)
        return selectedOption;
    };

    ///Province
    const handleSelectSaleArea = (selectedOption) => {
        setCust_province(selectedOption.value)
        console.log(selectedOption)
        return selectedOption;
    };

    ///Sales Person
    const handleSelectSale = (selectedOption) => {
        setCust_salePerson(selectedOption.value)
        console.log(selectedOption)
        return selectedOption;
    };

    ///  Farming or Hunting

    const handleClick = (e) => {
        console.log(e.target.value)
        setCust_HF(e.target.value)
        setFarmingOrHunting(e.target.value)

    }


    /// FarmingHunting Status
    const handleFarmingHuntingStatus = async (selectedOption) => {

        console.log(selectedOption)
        setCust_HFStatus(selectedOption.value)
        return selectedOption;
    };


    const handleSubmit = async (e) => {

        console.log('submit')

        e.preventDefault();

        const errors = {};

        // if (validator.isEmpty(group_id.toString())) {
        //     errors.group_id = 'MSO name is required';
        // }

        if (validator.isEmpty(cust_idERP.toString())) {
            errors.cust_idERP = 'Customer Name is required';
        }
        if (validator.isEmpty(cust_province.toString())) {
            errors.cust_province = 'Province Name is required';
        }

        if (validator.isEmpty(cust_salePerson.toString())) {
            errors.cust_salePerson = 'Sales Person is required';
        }

        if (validator.isEmpty(cust_group.toString())) {
            errors.cust_group = 'Customer Group is required';
        }

        if (validator.isEmpty(cust_centralBill.toString())) {
            errors.cust_centralBill = 'Central Billing  required';
        }

        // if (validator.isEmpty(cust_subContractor.toString())) {
        //     errors.cust_subContractor = 'Sub Contractor is required';
        // }

        if (validator.isEmpty(cust_subContractorSystem.toString())) {
            errors.cust_subContractorSystem = 'Sub Contractor system  is required';
        }

        // if (validator.isEmpty(cust_painDealer.toString())) {
        //     errors.cust_painDealer = 'Pain Dealer is required';
        // }

        // if (validator.isEmpty(cust_HF.toString())) {
        //     errors.cust_HF = 'Please provide Farming or Hunting';
        // }

        // if (validator.isEmpty(cust_HFStatus.toString())) {
        //     errors.cust_HFStatus = 'Status is required';
        // }

        // if (farmingOrHunting === 'Farming') {
        //     if (validator.isEmpty(cust_HFSystem.toString())) {
        //         errors.cust_HFSystem = 'Farming System is required';
        //     }
        // }

        // // } else {
        // //     errors.cust_HFSystem = false;
        // // }

        // if (farmingOrHunting === 'Farming') {
        //     if (validator.isEmpty(cust_segment.toString())) {
        //         errors.cust_segment = 'Customer Segment is required';
        //     }
        // }
        // // } else {
        // //     errors.cust_segment = false;
        // // }


        setFormErrors(errors);

        console.log(errors)

        if (Object.keys(errors).length === 0) {
            const data = {
                'group_id': null,
                'cust_idERP': cust_idERP,
                'cust_province': cust_province,
                'cust_salePerson': cust_salePerson,
                'cust_group': cust_group,
                'cust_centralBill': cust_centralBill,
                'cust_subContractor': cust_subContractor,
                'cust_subContractorSystem': cust_subContractorSystem,
                'cust_painDealer': cust_painDealer,
                // 'cust_HF': cust_HF,
                // 'cust_HFStatus': cust_HFStatus,
                // 'cust_HFSystem': cust_HFSystem,
                // 'cust_segment': cust_segment,
                'cust_remark': cust_remark,
            }
            console.log(data)
            axios.post(`${process.env.REACT_APP_API_URL}/value-chain`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => {
                    console.log(res)

                    if (res.data.error) {

                        Swal.fire({
                            title: res.data.message['cust_idERP'],
                            text: 'Please try again later',
                            icon: 'error',

                        })

                    } else {

                        Swal.fire({
                            title: 'Success!',
                            text: res.data.messages['success'],
                            icon: 'success',
                            confirmButtonText: 'OK'
                        })

                        console.log(res.data['lastID'])

                        if (res.data['lastID']) {
                            navigate(`/value-chain-detail/${res.data['lastID']}`);
                        }

                    }
                })
                .catch(error => {
                    console.log(error)

                    Swal.fire({
                        title: error.message,
                        text: 'Please try again later',
                        icon: 'error',

                    })

                })
        }

    }



    return (
        <>
            <div className="pagetitle">
                <h1>Create New Non-MSO Customer</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"> Create New Non-MSO Customer</li>
                        <li className="breadcrumb-item">Create</li>
                    </ol>
                </nav>
            </div>



            <section className="section">
                <div className="row">
                    <div className="col-lg-12">

                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Create New Data </h5>
                                <form >



                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Customer Name</label>
                                        <div className="col-sm-10">
                                            <ApiSearchSelectEmCust
                                                onChangeType={handleSelectCust}
                                            />
                                            {formErrors.cust_idERP && <div className="form-control-feedback">{formErrors.cust_idERP}</div>}

                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Province</label>
                                        <div className="col-sm-10">
                                            <SelectemSaleArea
                                                onChangeType={handleSelectSaleArea}
                                            />
                                            {formErrors.cust_province && <div className="form-control-feedback">{formErrors.cust_province}</div>}

                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Sales Person</label>
                                        <div className="col-sm-10">
                                            <SelectempSales
                                                onChangeType={handleSelectSale}
                                            />
                                            {formErrors.cust_salePerson && <div className="form-control-feedback">{formErrors.cust_salePerson}</div>}

                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Customer Group</label>
                                        <div className="col-sm-10">
                                            <select
                                                onChange={e => setCust_group(e.target.value)}
                                                value={cust_group}
                                                className="form-select">
                                                <option value=""> - Choose - </option>
                                                <option value="IB">IB อู่อิสระ</option>
                                                <option value="OE">OE ศูนย์บริการ</option>
                                            </select>
                                            {formErrors.cust_group && <div className="form-control-feedback">{formErrors.cust_group}</div>}

                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Central Billing</label>
                                        <div className="col-sm-10">
                                            <select
                                                onChange={e => setCust_centralBill(e.target.value)}
                                                value={cust_centralBill}
                                                className="form-select">
                                                <option value=""> - Choose - </option>
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>
                                            </select>
                                            {formErrors.cust_centralBill && <div className="form-control-feedback">{formErrors.cust_centralBill}</div>}

                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Sub Contractor</label>
                                        <div className="col-sm-10">
                                            <ApiSearchSelectEmCust
                                                onChangeType={handleSelectSubContractor}
                                            />
                                            {formErrors.cust_subContractor && <div className="form-control-feedback">{formErrors.cust_subContractor}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Sub Contractor System</label>
                                        <div className="col-sm-10">
                                            <select
                                                onChange={e => setCust_subContractorSystem(e.target.value)}
                                                value={cust_subContractorSystem}
                                                className="form-select">
                                                <option value=""> - Choose - </option>
                                                <option value="GL Full">GL Full</option>
                                                <option value="BRC Full">BRC Full</option>
                                                <option value="Non Full">Non Full</option>
                                                <option value="Competitor ">Competitor </option>
                                            </select>
                                            {formErrors.cust_subContractorSystem && <div className="form-control-feedback">{formErrors.cust_subContractorSystem}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Paint Dealer</label>
                                        <div className="col-sm-10">
                                            <ApiSearchSelectEmCust
                                                onChangeType={handleSelectPainDealer}
                                            />
                                            {formErrors.cust_painDealer && <div className="form-control-feedback">{formErrors.cust_painDealer}</div>}
                                        </div>
                                    </div>
                                    <hr />




                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Remark</label>
                                        <div className="col-sm-10">
                                            <textarea
                                                onChange={e => setCust_remark(e.target.value)}
                                                value={cust_remark}
                                                rows="3"
                                                className="form-control" />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <Button variant="primary" onClick={handleSubmit}>
                                            Create
                                        </Button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default NoneMSOValueChainCreate;