import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import validator from "validator";

// import BodyFillerBrand from './_BrandBodyFiller.json';
// import PrimerBrand from './_BrandPrimer.json';
import Brand from "./_BrandData.json";
import axiosInstance from "../../axiosInstance";

// import { logout, login, isLogin, getToken } from '../../auth';

const ValueChain_productMix = ({ cust_id, customer }) => {
  const [brands, setBrands] = useState([]);
  const [brandTypes, setBrandTypes] = useState([]);

  const [selectedOptionType, setSelectedOptionType] = useState([]);
  const [brandData1, setBrandData1] = useState([]);
  const [brandData1Select, setBrandData1Select] = useState();
  const [brandData1Percent, setBrandData1Percent] = useState(null);

  const [brandData2, setBrandData2] = useState([]);
  const [brandData2Select, setBrandData2Select] = useState([]);
  const [brandData2Percent, setBrandData2Percent] = useState(null);

  const [brandData3, setBrandData3] = useState([]);
  const [brandData3Select, setBrandData3Select] = useState([]);
  const [brandData3Percent, setBrandData3Percent] = useState(null);

  const [pdm_id, setPdm_id] = useState();
  const [pdm_status, setPdm_status] = useState("I");

  const [data, setData] = useState([]);

  const [modelTitle, setModelTitle] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formErrors, setFormErrors] = useState([]);

  const [optionsType, setOptionsType] = useState([]);

  useEffect(() => {
    getProductMix();
    fetchData();
  }, []);

  useEffect(() => {
    // console.log(brandData1Percent);
    if (Number(brandData1Percent) === 100) {
      // console.log('set brandData1 ,2 == dont have')
      setBrandData2Select("0");
      setBrandData3Select("0");
    }

    var check3 = Number(brandData1Percent) + Number(brandData2Percent);

    if (check3 == 100) {
      setBrandData3Select("0");
    }
  }, [brandData1Percent, brandData2Percent]);

  const fetchData = async () => {
    try {
      const [brandTypesResponse, brandsResponse] = await Promise.all([
        axiosInstance.get("/brandTypes"),
        axiosInstance.get("/brands"),
      ]);
      setBrandTypes(brandTypesResponse.data);
      setOptionsType(brandTypesResponse.data);
      setBrands(brandsResponse.data);

      setBrandData1(brandsResponse.data);
      setBrandData2(brandsResponse.data);
      setBrandData3(brandsResponse.data);

      // console.log(brandsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      //   setLoading(false);
    }
  };

  const handleSelectOptionsType = (selectedOptionType) => {
    // console.log(selectedOptionType.target.value);
    // return true;
    setBrandData1Select([]);
    setBrandData2Select([]);
    setBrandData3Select([]);

    setBrandData1([]);
    setBrandData2([]);
    setBrandData3([]);

    setSelectedOptionType(selectedOptionType.target.value);
    // console.log(selectedOptionType.target.value);
    // const BrandData = Brand[selectedOptionType["value"]];
    // const BrandData = Brand[selectedOptionType.target.value];
    // console.log(BrandData);
    // // const filterBrands = brands.filter((itemBrands)=>{
    // //   console.log(itemBrands)
    // //   // itemBrands.val
    // // });
    // setBrandData1(BrandData);
    // console.table(brands);

    const brandsFiltered1 = brands.filter((item) => {
      return item.brandTypeID === parseInt(selectedOptionType.target.value);
    });

    // console.log(brandsFiltered1);
    setBrandData1(brandsFiltered1);
    // setBrandData2(filtered);
    // setBrandData3(filtered);
  };

  const handleSelectBrand1 = (BrandData1Select) => {
    // console.log(BrandData1Select.target.value);
    setBrandData1Select(BrandData1Select.target.value);

    // const filtered = brandData1.filter((item) => {
    //   return item.value != BrandData1Select["value"];
    // });

    const filtered = brandData1.filter((item) => {
      return item.brandID != BrandData1Select.target.value;
    });
    setBrandData2(filtered);
    // const optionDont = [
    //   {
    //     value: "-",
    //     label: "Don't have",
    //     type: "Body Filler",
    //   },
    // ];
    // const filteredWithDont = [...optionDont, ...filtered];
    // setBrandData2(filteredWithDont);
  };

  const handleSelectBrand2 = (BrandData2Select) => {
    setBrandData2Select(BrandData2Select.target.value);
    // const filtered = brandData2.filter((item) => {
    //   return item.value != BrandData2Select["value"] || item.value === "-";
    // });

    const filtered = brandData2.filter((item) => {
      return item.brandID != BrandData2Select.target.value;
    });

    setBrandData3(filtered);
  };

  const calculateSum = () => {
    // const { brandData1Percent, brandData2Percent, brandData3Percent } = useState;
    return (
      Number(brandData1Percent) +
      Number(brandData2Percent) +
      Number(brandData3Percent)
    );
  };

  const model = (e) => {
    // console.log(e)

    if (e === null) {
      const filteredOptionType = optionsType.filter((item1) => {
        return !data.some((item2) => item1.value === item2.pdm_type);
      });
      setOptionsType(filteredOptionType);

      setShow(true);
      setPdm_id(null);
      setSelectedOptionType();
      setBrandData1Select();
      setBrandData1Percent("");
      setBrandData2Select();
      setBrandData2Percent("");
      setBrandData3Select();
      setBrandData3Percent("");

      setModelTitle("Create New MSO");
    } else {
      fetchData();
      console.log("edit");
      setShow(true);
      setPdm_id(e);
      // setModelTitle('MSO Detail')
      // console.log('edit from')
    }
  };

  const getProductMix = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/value-chain-productmix/${cust_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (selectedOptionType === null || selectedOptionType === undefined) {
      errors.selectedOptionType = "Product Type is required";
    }

    if (brandData1Select === null || brandData1Select === undefined) {
      errors.brandData1Select = "Brand #1 is required";
    }
    if (validator.isEmpty(brandData1Percent)) {
      errors.brandData1Percent = "%  of  Brand #1  is required";
    }

    // brand 2
    if (
      brandData2Select !== "0" &&
      (brandData2Select === null || brandData2Select === undefined)
    ) {
      errors.brandData2Select = "Brand #2 is required";
    }
    if (brandData2Select !== "0" && validator.isEmpty(brandData2Percent)) {
      errors.brandData2Percent = "% of Brand #2 is required";
    }

    if (sum < 100) {
      // brand 3
      if (
        brandData3Select !== "0" &&
        (brandData3Select === null || brandData3Select === undefined)
      ) {
        errors.brandData3Select = "Brand #3 is required";
      }

      if (brandData3Select !== "0" && validator.isEmpty(brandData3Percent)) {
        errors.brandData3Percent = "% of Brand #3 is required";
      }
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = {
        cust_id: cust_id,
        pdm_type: selectedOptionType,
        pdm_name1: brandData1Select,
        pdm_percent1: brandData1Percent,
        pdm_name2: brandData2Select || 0,
        pdm_percent2: brandData2Percent || 0,
        pdm_name3: brandData3Select || 0,
        pdm_percent3: brandData3Percent || 0,
        pdm_status: pdm_status,
      };
      // console.log(con_id)
      // console.log(data)

      // console.log(data);
      // return true;
      if (pdm_id === null) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/value-chain-productmixCreate`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);

            Swal.fire({
              title: "Success!",
              text: res.data["message"],
              icon: "success",
              confirmButtonText: "OK",
            });

            getProductMix();
            setShow(false);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: error.message,
              text: "Please try again later",
              icon: "error",
            });
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/value-chain-productmixUpdate/${pdm_id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: res.data["message"],
              icon: "success",
              confirmButtonText: "OK",
            });

            getProductMix();
            setShow(false);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: error.message,
              text: "Please try again later",
              icon: "error",
            });
          });
      }
    }
  };
  const handleFocus = (event) => {
    event.target.select();
  };

  const sum = calculateSum();
  return (
    <div className="row">
      <div
        className="filter text-end cursor-pointer p-2"
        onClick={() => model(null)}
      >
        <div className="icon">
          <i className="bx bxs-folder-plus" style={{ fontSize: "40px" }}></i>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="from-control ">
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label"> Product Type</label>
              <div className="col-sm-8">
                <select
                  className="form-control"
                  onChange={handleSelectOptionsType}
                  value={selectedOptionType}
                  placeholder="- Choose -"
                >
                  <option value=""> - Choose -</option>
                  {optionsType.map((brandType, index) => (
                    <option key={index} value={brandType.brandTypeID}>
                      {brandType.brandTypeName}
                    </option>
                  ))}
                  <option></option>
                </select>

                {formErrors.selectedOptionType && (
                  <div className="form-control-feedback">
                    {formErrors.selectedOptionType}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label"> Brand #1</label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  onChange={handleSelectBrand1}
                  value={brandData1Select}
                  placeholder="- Choose -"
                >
                  <option value=""> - Choose -</option>
                  {brandData1.map((brands, index) => (
                    <option key={index} value={brands.brandID}>
                      {brands.brandName}
                    </option>
                  ))}
                </select>

                {formErrors.brandData1Select && (
                  <div className="form-control-feedback">
                    {formErrors.brandData1Select}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <input
                  value={brandData1Percent || 0}
                  onChange={(e) => setBrandData1Percent(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder="%"
                  onFocus={handleFocus}
                />
                {formErrors.brandData1Percent && (
                  <div className="form-control-feedback">
                    {formErrors.brandData1Percent}
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-4 col-form-label"> Brand #2</label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  onChange={handleSelectBrand2}
                  value={brandData2Select}
                  placeholder="- Choose -"
                >
                  <option value=""> - Choose -</option>
                  <option value="0"> Don't have </option>
                  {brandData2.map((brands, index) => (
                    <option key={index} value={brands.brandID}>
                      {brands.brandName}
                    </option>
                  ))}
                </select>

                {formErrors.brandData2Select && (
                  <div className="form-control-feedback">
                    {formErrors.brandData2Select}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <input
                  value={brandData2Percent || 0}
                  onChange={(e) => setBrandData2Percent(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder="%"
                  onFocus={handleFocus}
                />
                {formErrors.brandData2Percent && (
                  <div className="form-control-feedback">
                    {formErrors.brandData2Percent}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Brand #3</label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  onChange={(e) => setBrandData3Select(e.target.value)}
                  value={brandData3Select}
                  placeholder="- Choose -"
                >
                  <option value=""> - Choose -</option>
                  <option value="0"> Don't have </option>
                  {brandData3.map((brands, index) => (
                    <option key={index} value={brands.brandID}>
                      {brands.brandName}
                    </option>
                  ))}
                </select>

                {formErrors.brandData3Select && (
                  <div className="form-control-feedback">
                    {formErrors.brandData3Select}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <input
                  value={brandData3Percent || 0}
                  onChange={(e) => setBrandData3Percent(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder="%"
                  onFocus={handleFocus}
                />
                {formErrors.brandData3Percent && (
                  <div className="form-control-feedback">
                    {formErrors.brandData3Percent}
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-4 col-form-label"> </label>
              <div className="col-sm-4"></div>
              <div className="col-sm-4">
                <div>{sum} %</div>
                <div className="g-font-size-12 text-danger">
                  * อัตราส่วน % ต้องเท่ากับ 100% เท่านั้น
                </div>
              </div>
            </div>

            <div className="row mb-3">
              {pdm_id && (
                <div className="row mb-3">
                  <label className="col-sm-4 col-form-label"> Status</label>
                  <div className="col-sm-8">
                    <select
                      onChange={(e) => setPdm_status(e.target.value)}
                      value={pdm_status}
                      className="form-control"
                    >
                      <option value="1" {...(pdm_status === "1" && "selected")}>
                        {" "}
                        Active{" "}
                      </option>
                      <option value="0" {...(pdm_status === "0" && "selected")}>
                        {" "}
                        Inactive{" "}
                      </option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={`primary ${sum !== 100 && `disabled`} `}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-12">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Product Type</th>
              <th scope="col" className="text-right">
                Brand#1
              </th>
              <th scope="col" className="text-right">
                %Brand#1
              </th>
              <th scope="col" className="text-right">
                Brand#2
              </th>
              <th scope="col" className="text-right">
                %Brand#2
              </th>
              <th scope="col" className="text-right">
                Brand#3
              </th>
              <th scope="col" className="text-right">
                %Brand#3
              </th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              <>
                {data.map((item, key) => (
                  <tr key={key}>
                    <th scope="row">{key + 1}</th>
                    <td>{item["brandTypeName"]} </td>
                    <td className="text-right">{item["brandName1"]} </td>
                    <td className="text-right">{item["pdm_percent1"]}%</td>
                    <td className="text-right">{item["brandName2"]} </td>
                    <td className="text-right">{item["pdm_percent2"]}%</td>
                    <td className="text-right">{item["brandName3"]} </td>
                    <td className="text-right">{item["pdm_percent3"]}%</td>
                    <td>
                      {item["pdm_status"] === "1" ? "Active" : "Inactive"}
                    </td>
                    <td>
                      <i
                        onClick={() =>
                          model(
                            item["pdm_id"],

                            setSelectedOptionType(item["pdm_type"]),
                            setBrandData1Select(item["pdm_name1"]),
                            setBrandData1Percent(item["pdm_percent1"]),

                            setBrandData2Select(item["pdm_name2"]),
                            setBrandData2Percent(item["pdm_percent2"]),

                            setBrandData3Select(item["pdm_name3"]),
                            setBrandData3Percent(item["pdm_percent3"]),

                            setPdm_status(item["pdm_status"])
                          )
                        }
                        className="bx bxs-edit-location"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <td className="text-center" colSpan={8}>
                data not found
              </td>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ValueChain_productMix;
