import React, { useState, useCallback, useEffect } from 'react';
import axios from "axios";
import { useDropzone } from 'react-dropzone';
import { logout, login, isLogin, getToken } from '../../auth';


const ValueChain_photo = ({ cust_id }) => {

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        fileList()
    }, [])

    const fileList = () => {

        axios.get(`${process.env.REACT_APP_API_URL}value-chain-file-list/${cust_id}`,
            {
                headers: { 'Authorization': `${process.env.REACT_APP_API_KEY}` },
            })
            .then(response => {
                console.log(response);
                setUploadedFiles(response.data.files);

            })
            .catch(error => {
                console.error('File list error:', error);
            });
    }

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('cust_id', cust_id);

            axios.post(`${process.env.REACT_APP_API_URL}value-chain-file/upload`, formData, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(percentCompleted);
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            })
                .then(response => {
                    console.log(response)
                    // setUploadedFiles([...uploadedFiles, { name: file.name, url: response.data.url }]);
                    setUploadedFiles([...uploadedFiles, response.data]);
                    setUploadProgress(0);
                    fileList()

                })
                .catch(error => {
                    console.error('File upload error:', error);
                });
        });
    }, [uploadedFiles]);


    const handleDelete = (file) => {
        const updatedFiles = uploadedFiles.filter(f => f.filename !== file.filename);
        setUploadedFiles(updatedFiles);
        axios.delete(`${process.env.REACT_APP_API_URL}value-chain-file/${file.filename}/${cust_id}`,
            {
                headers: { 'Authorization': `${process.env.REACT_APP_API_KEY}` },
            })
            .then(response => {
                console.log('File deleted:', response);
            })
            .catch(error => {
                console.error('File delete error:', error);
            });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div>
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
            </div>

            {uploadProgress > 0 && (
                <div className="upload-progress">
                    <p>Upload Progress: {uploadProgress}%</p>
                    <progress max="100" value={uploadProgress}></progress>
                </div>
            )}

            <div className="file-list">
                <h5>Uploaded Files:</h5>
                <ul>
                    {uploadedFiles.map((file, index) => (
                        <li key={index}>



                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-sm-3">
                                        <img src={file.path + '/' + file.filename} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="card-body">
                                            <h5 className="card-title"> <button className='btn btn-primary' onClick={() => handleDelete(file)}>Delete</button></h5>
                                            <p className="card-text"> {file.filename}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <a href={file.path + '/' + file.filename} target="_blank" rel="noopener noreferrer">

                            </a> */}

                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ValueChain_photo;