import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

function EmCust({ onChangeType, value }) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [defaultValue, setDefaultValue] = useState(value['value']);

    useEffect(() => {
        // console.log(value)
        if (!value) {
            console.log(value)
            // setDefaultValue(value['value'])
            getEMCust()
        }
    

    }, [onChangeType]);

    // ${defaultValue}

    const fetchData = async (inputValue, callback) => {

        // const text = (inputValue !== '' ? inputValue : value)
        // console.log(inputValue)

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/erp-emcust/${inputValue}`, {
                headers: {
                     'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            })

            const data = response.data.data;

            const options = data.map((item) => ({
                value: item.CustID,
                label: item.CustName,
                label: item.CustCode + ' / ' + item.CustShortName  ,
            }));

            callback(options);
        } catch (error) {
            console.error('API Request Error:', error);
            callback([]);
        }
    };

    const getEMCust = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/erp-emcust/${defaultValue}`, {
            headers: {
                 'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                // console.log(res)
                const data = res.data.data.map((item) => ({
                    value: item.CustID,
                    label: item.CustCode + ' / ' + item.CustName,

                }));
                setOptions(data)
            })
            .catch(error => {
                console.log(error)
            })

    }

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onChangeType(selectedOption)
        // console.log(selectedOption)
    };

    // const options2 = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ]


    return (

        <AsyncSelect
            cacheOptions
            loadOptions={(inputValue, callback) => fetchData(inputValue, callback)}
            placeholder="Search..."

            onChange={handleSelectChange}
            value={selectedOption}
            options={options}

        />

        // <Select
        // value={selectedOption}
        // onChange={handleSelectChange}
        // options={options}
        // loadOptions={(inputValue, callback) => getEMCust(inputValue, callback)}
        // value={options.find(option => option.value === defaultValue)}
        // placeholder="Search..."
        // isLoading={true}

        // isSearchable

        // />
    );
}

export default EmCust;



