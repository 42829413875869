import React, { useEffect, useState } from "react";
import axios from "axios";
// import Select from 'react-select';
import Swal from "sweetalert2";
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import validator from "validator";

// import { logout, login, isLogin, getToken } from '../../auth';

const ValueChain_vas = ({ cust_id, customer, onUpdated }) => {

 /*
  console.log(customer['cust_vasConsultIncreaseProductivity'])

  const [cust_vasProfitManager, setCust_vasProfitManager] = useState({
    value: customer["cust_vasProfitManager"],
    label: customer["cust_vasProfitManager"],
  });
  const [cust_vasWFM, setCust_vasWFM] = useState({
    value: customer["cust_vasWFM"],
    label: customer["cust_vasWFM"],
  });
  const [cust_vas5S, setCust_vas5S] = useState({
    value: customer["cust_vas5S"],
    label: customer["cust_vas5S"],
  });
  const [cust_vasConsult, setCust_vasConsult] = useState({
    value: customer["cust_vasConsult"],
    label: customer["cust_vasConsult"],
  });
  const [cust_vasAudit, setCust_vasAudit] = useState({
    value: customer["cust_vasAudit"],
    label: customer["cust_vasAudit"],
  });
  const [cust_vasWarranty, setCust_vasWarranty] = useState({
    value: customer["cust_vasWarranty"],
    label: customer["cust_vasWarranty"],
  });
*/
  const [formErrors, setFormErrors] = useState([]);

  // console.log(cust_vasProfitManager)

  const [selectedOptions, setSelectedOptions] = useState({
    cust_vasConsultIncreaseProductivity: customer['cust_vasConsultIncreaseProductivity'],
    cust_vasConsultReduceRework: customer['cust_vasConsultReduceRework'],
    cust_vasConsultSeminar: customer['cust_vasConsultSeminar'],
    cust_vasConsult5S: customer['cust_vasConsult5S'],
    cust_vasConsultShopLayOut: customer['cust_vasConsultShopLayOut'],
    cust_vasDigitalWFM: customer['cust_vasDigitalWFM'],
    cust_vasDigitalPFM: customer['cust_vasDigitalPFM'],
    cust_vasDigitalRefinity: customer['cust_vasDigitalRefinity'],
    cust_vasDigitalSpectroSoftware: customer['cust_vasDigitalSpectroSoftware'],
    cust_vasAuditCurrentYear: customer['cust_vasConsultIcust_vasAuditCurrentYearncreaseProductivity'],
    cust_vasAuditSkillAssetment: customer['cust_vasAuditSkillAssetment'],
    cust_vasWarranty: customer['cust_vasWarranty'],
  });

  // useEffect(() => {
  //     console.log(selectedOptions)

  // }, [selectedOptions])

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedOptions((prevOptions) => ({ ...prevOptions, [name]: value }));
    console.log(name + " " + value);
  };

  const handleUpdated = (status) => {
    onUpdated(status);
    return status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    /*
    // Check each dropdown for null or undefined values
    Object.keys(selectedOptions).forEach((name) => {
      if (!selectedOptions[name]) {
        errors[name] = `${name} is required`;
      }
    });

    // Set errors in the state
    setFormErrors(errors);
    // console.table(errors['cust_vasConsultIncreaseProductivity'])

    // If there are errors, you might choose to stop the submission
    if (Object.values(errors).some((error) => error !== null)) {
      console.log("Form has errors. Please correct them before submitting.");
      return;
    }
*/
    // const data = {
    //     'cust_vasProfitManager': cust_vasProfitManager['value'],
    //     'cust_vasWFM': cust_vasWFM['value'],
    //     'cust_vas5S': cust_vas5S['value'],
    //     'cust_vasConsult': cust_vasConsult['value'],
    //     'cust_vasAudit': cust_vasAudit['value'],
    //     'cust_vasWarranty': cust_vasWarranty['value'],
    // }

    // 'cust_vasConsultIncreaseProductivity',
    //     'cust_vasConsultReduceRework',
    //     'cust_vasConsultSeminar',
    //     'cust_vasConsult5S',
    //     'cust_vasConsultShopLayOut',
    //     'cust_vasDigitalWFM',
    //     'cust_vasDigitalPFM',
    //     'cust_vasDigitalRefinity',
    //     'cust_vasDigitalSpectroSoftware',
    //     'cust_vasAuditCurrentYear',
    //     'cust_vasAuditSkillAssetment',
    //     'cust_vasWarranty',

    // console.log(con_id)
    // console.log(data)

    // console.log(selectedOptions);
    // return true;
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/value-chain-update/${cust_id}`,
        selectedOptions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data)

        handleUpdated(true);
        Swal.fire({
          title: "Success!",
          text: res.data["message"],
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: error.message,
          text: "Please try again later",
          icon: "error",
        });
      });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-lg-2">
          <h5 className="card-title">VAS - Consult</h5>
        </div>
        <div className="col-lg-10">
          <div className="row">
            <label className="col-sm-3 col-form-label">
              Increase Productivity
              <p>เพิ่มผลผลิต</p>
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasConsultIncreaseProductivity"
                value={selectedOptions.cust_vasConsultIncreaseProductivity}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="Y">มี</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasConsultIncreaseProductivity"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasConsultIncreaseProductivity"]}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Reduce Rework
              <p>ลดจำนวนงาน Rework</p>
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasConsultReduceRework"
                value={selectedOptions.cust_vasConsultReduceRework}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="Y">มี</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasConsultReduceRework"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasConsultReduceRework"]}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Seminar
              <p>การจัดอบรม</p>
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasConsultSeminar"
                value={selectedOptions.cust_vasConsultSeminar}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="Y">มี</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasConsultSeminar"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasConsultSeminar"]}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              5S
              <p>5ส</p>
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasConsult5S"
                value={selectedOptions.cust_vasConsult5S}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="Y">มี</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasConsult5S"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasConsult5S"]}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Shop Layout
              <p>การวางแปลนพื้นที่ทำงาน</p>
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasConsultShopLayOut"
                value={selectedOptions.cust_vasConsultShopLayOut}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="Y">มี</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasConsultShopLayOut"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasConsultShopLayOut"]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="row mb-3">
        <div className="col-lg-2">
          <h5 className="card-title">VAS - Digital</h5>
        </div>
        <div className="col-lg-10">
          <div className="row">
            <label className="col-sm-3 col-form-label">
              WFM
              <br />
              Work Flow Manager
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasDigitalWFM"
                value={selectedOptions.cust_vasDigitalWFM}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="YY">มีและใช้งาน</option>
                <option value="YN">มีแต่ไม่ใช้งาน</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasDigitalWFM"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasDigitalWFM"]}
                </div>
              )}
            </div>
          </div>

          <di v className="row mb-3">
            <label className="col-sm-3 col-form-label">
              PFM
              <br />
              ProFit Manager
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasDigitalPFM"
                value={selectedOptions.cust_vasDigitalPFM}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="YY">มีและใช้งาน</option>
                <option value="YN">มีแต่ไม่ใช้งาน</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasDigitalPFM"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasDigitalPFM"]}
                </div>
              )}
            </div>
          </di>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Refinity</label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasDigitalRefinity"
                value={selectedOptions.cust_vasDigitalRefinity}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="YY">มีและใช้งาน</option>
                <option value="YN">มีแต่ไม่ใช้งาน</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasDigitalRefinity"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasDigitalRefinity"]}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Spectro + Software
              <br />
              เครื่องมือวัดค่าสีพร้อมโปรแกรม
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasDigitalSpectroSoftware"
                value={selectedOptions.cust_vasDigitalSpectroSoftware}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="YY">มีและใช้งาน</option>
                <option value="YN">มีแต่ไม่ใช้งาน</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasDigitalSpectroSoftware"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasDigitalSpectroSoftware"]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className="row mb-3">
        <div className="col-lg-2">
          <h5 className="card-title">VAS - Audit</h5>
        </div>

        <div className="col-lg-10">
          <div className="row">
            <label className="col-sm-3 col-form-label">
              Current Year
              <br />
              ได้รับการตรวจ Audit ในปีปัจจุบัน
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasAuditCurrentYear"
                value={selectedOptions.cust_vasAuditCurrentYear}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="Y">มี</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasAuditCurrentYear"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasAuditCurrentYear"]}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Skill Assetment
              <br />
              ได้รับการตรวจ ประเมินทักษะ
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasAuditSkillAssetment"
                value={selectedOptions.cust_vasAuditSkillAssetment}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="Y">มี</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasAuditSkillAssetment"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasAuditSkillAssetment"]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className="row mb-3">
        <div className="col-lg-2">
          <h5 className="card-title">Warranty</h5>
        </div>

        <div className="col-lg-10">
          <div className="row">
            <label className="col-sm-3 col-form-label">
              Warranty
              <br />
              มีการรับประกันงานซ่อม
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="cust_vasWarranty"
                value={selectedOptions.cust_vasWarranty}
                onChange={handleSelectChange}
              >
                <option value="">- Choose -</option>
                <option value="Y">มี</option>
                <option value="N">ไม่มี</option>
              </select>
              {formErrors["cust_vasWarranty"] && (
                <div className="form-control-feedback">
                  {formErrors["cust_vasWarranty"]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3  ">
        <label className="col-sm-3 col-form-label"> </label>
        <div className="col-sm-9">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ValueChain_vas;
