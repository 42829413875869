import React, { useState } from "react";
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingOverLay from "../loading/LoadingOverLay";
// import LoadingSkeleton from "../loading/LoadingSkeleton";

import Loading from "../loading/Loading";
import DataTable from 'react-data-table-component';



const ValueChain_activities = ({ cust_id }) => {
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const datePickerStyle = { width: '100%' };
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        getActivities();
    };

    const getActivities = () => {
        setPending(true)

        setIsLoading(true);
        const data = {
            'startDate': startDate,
            'endDate': endDate,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/value-chain-activities/${cust_id}`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                console.log(res.data)
                if (res.data.data) {
                    setData(res.data.data);
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                setPending(false)

            });
    };

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,

        },
        {
            name: 'CaseNo',
            selector: row => row.CaseNo,
        },
        {
            name: 'ชื่อ-รหัสพนักงาน',
            selector: row => row.EmpCode + ' ' + row.EmpName,
        },
        {
            name: 'สถานะ',
            selector: row => row.Meeting_typeName,
        },
        {
            name: 'รายละเอียดการเข้าพบ',
            selector: row => row.Meeting_notes,
        },
        {
            name: 'เรื่องที่เข้าพบที่ 1',
            selector: row => row.subcode + ' ' + row.subject,
        },
        {
            name: 'เรื่องที่เข้าพบที่ 2',
            selector: row => row.subcode2 + ' ' + row.subject2,
        },
        {
            name: 'เรื่องที่เข้าพบที่ 3',
            selector: row => row.subcode3 + ' ' + row.subject3,
        },
        {
            name: 'บุคคลที่เข้าพบที่ 1',
            selector: row => row.Custmeetname + ' ' + row.position + ' ' + row.phone,
        },
        {
            name: 'บุคคลที่เข้าพบที่ 2',
            selector: row => row.Custmeetname2 + ' ' + row.position2 + ' ' + row.phone2,
        },
        {
            name: 'บุคคลที่เข้าพบที่ 3',
            selector: row => row.Custmeetname3 + ' ' + row.position3 + ' ' + row.phone3,
        },
        {
            name: 'วันเวลาที่นัดพบ',
            selector: row => row.Date + ' ' + row.Time + '-' + row.Timeout,
        },
        {
            name: 'วันเวลาที่เข้าพบ',
            selector: row => row.Datemeeting + ' ' + row.Timemeetingin + '-' + row.Timemeetingout,
        },
    ];


    return (
        <>
            {/* {isLoading && <LoadingOverLay />} */}
            <div className="row">
                <div className="p">

                    <div className="row g-3 d-flex justify-content-end" style={{ zIndex: '100000' }}>
                        <div className="col-md-3 d-grid">
                            <label> Start Date</label>
                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                                className="form-control"
                                style={{ width: '100%' }}
                                dateFormat="d/MM/yyyy"
                            />
                        </div>
                        <div className="col-md-3 d-grid">
                            <label> End Date</label>
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="End Date"
                                className="form-control d-grid"
                                style={{ width: '100%' }}
                                dateFormat="d/MM/yyyy"
                            />
                        </div>

                        <div className="col-md-3">
                            <label>  </label>
                            <div className="d-grid ">
                                <button type="button" className="btn btn-success btn-block" onClick={handleSubmit} >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 pt-3">

                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            fixedHeader
                            progressPending={pending}
                            progressComponent={<Loading />}
                        />
                    </div>

                </div>

            </div>
        </>
    );
};

export default ValueChain_activities;
