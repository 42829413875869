
import React, { useEffect } from 'react';
import { Navigate, Redirect, Route } from 'react-router-dom';
// import { useAuth } from './AuthContext';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    // const { user } = useAuth();
    const token = localStorage.getItem('token')
     // console.log(token)

    if (token) {
        return <RouteComponent />
    }

    return <Navigate to="/" replace={true} />

    // return <Component />

    // return (
    //     <Route
    //         {...rest}
    //         render={(props) =>
    //             user ? <Component {...props} /> : <Navigate to="/login" />
    //         }
    //     />
    // );
};

export default PrivateRoute;

///https://github.com/akursat/react-router-example/blob/main/src/PrivateRoute.tsx