import React, { useState, useEffect } from "react";
import axios from "axios";

import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import LoadingOverLay from "../loading/LoadingOverLay";
import { Link } from "react-router-dom";

const Insurance = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [showMyModal, setShowMyModal] = useState(false);
  const showModal = () => setShowMyModal(false);
  const handleClose = () => setShowMyModal(false);

  const [insurances, setInsurances] = useState([]);
  const [form, setForm] = useState({
    ins_id: "",
    ins_name: "",
    ins_type: "",
    ins_status: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchInsurances();
  }, []);

  const fetchInsurances = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/setup-dbinsurance`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setInsurances(res.data);
    } catch (error) {
      console.error("Error fetching insurances", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(isEditing, form);
    // return true;
    try {
      if (isEditing) {
        setIsLoading(true);

        await axios
          .put(
            `${process.env.REACT_APP_API_URL}/setup-dbinsurance-update/${form.ins_id}`,
            {
              ins_name: form.ins_name,
              ins_type: form.ins_type,
              ins_status: form.ins_status,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            Swal.fire({
              title: "update successfully ",
              text: "",
              icon: "success",
            });
            setIsLoading(false);
            setShowMyModal(false);
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/setup-dbinsurance-create`,
            {
              ins_name: form.ins_name,
              ins_type: form.ins_type,
              ins_status: 1,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            Swal.fire({
              title: "Save successfully ",
              text: "",
              icon: "success",
            });
            setIsLoading(false);
            setShowMyModal(false);
          });
      }
      setForm({
        ins_id: "",
        ins_name: "",
        ins_type: "",
        ins_status: "",
      });
      setIsEditing(false);
      fetchInsurances();
    } catch (error) {
      setIsLoading(false);
      console.error("Error saving insurance", error);

      Swal.fire({
        title: error.message,
        text: "Error saving insurance",
        icon: "error",
      });
    }
  };

  const handleEdit = (insurance) => {
    setForm({
      ins_id: insurance.ins_id,
      ins_name: insurance.ins_name,
      ins_type: insurance.ins_type.toString(),
      ins_status: insurance.ins_status.toString(),
      ins_createDate: insurance.ins_createDate,
    });
    setIsEditing(true);

    setShowMyModal(true);
  };

  const handleDelete = async (ins_id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/insurances/${ins_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchInsurances();
    } catch (error) {
      console.error("Error deleting insurance", error);
    }
  };

  return (
    <>
      {isLoading && <LoadingOverLay />}

      <div className="pagetitle">
        <h1>Insurance </h1>
        <nav>
          <ol className="breadcrumb">
          <li className="breadcrumb-item">
              <Link to="/setup/main">
                <span> Set Up</span>
              </Link>
            </li>
            <li className="breadcrumb-item active">Insurance </li>
          </ol>
        </nav>
      </div>

      <div className="card">
        <div className="row pt-3 p-3 text-end ">
          <div className="col-md-9"> </div>
          <div className="col-md-2 text-end  "> </div>
          <div className="col-md-1">
            <div
              className="  text-end cursor-pointer "
              onClick={() => setShowMyModal(true)}
            >
              <div className="icon">
                <i
                  className="bx bxs-folder-plus"
                  style={{ fontSize: "40px" }}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <h5 className="card-title d-none">Insurance Lists</h5>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Insurance Company</th>
                <th>Type</th>
                <th>Status</th>
                <th className="d-none">วันที่สร้าง</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {insurances.map((insurance , key) => (
                <tr key={insurance.ins_id} className={insurance.ins_status === 1 ? " " : "text-danger"}>
                <td>{key+1}</td>
                  <td>{insurance.ins_name}</td>
                  <td>
                    {insurance.ins_type === "0"
                      ? "ระบุเฉพาะ อัตราส่วนเงินสด"
                      : "ระบุเฉพาะ อัตราส่วนเบี้ยห้าง,อัตราส่วนเบี้ยอู่"}
                  </td>
                  <td>{insurance.ins_status === 1 ? "Active" : "Inative"}</td>
                  <td className="d-none">{insurance.ins_createDate}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => handleEdit(insurance)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm btn-danger d-none"
                      onClick={() => handleDelete(insurance.ins_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        centered
        backdrop="static"
        size="lg"
        show={showMyModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" "> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="ins_name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="ins_name"
                  id="ins_name"
                  placeholder="Name"
                  value={form.ins_name}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="ins_type" className="form-label">
                  Type
                </label>
                <select
                  className="form-control"
                  name="ins_type"
                  id="ins_type"
                  value={form.ins_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="0">ระบุเฉพาะ อัตราส่วนเงินสด</option>
                  <option value="1">
                    ระบุเฉพาะ อัตราส่วนเบี้ยห้าง,อัตราส่วนเบี้ยอู่
                  </option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="ins_status" className="form-label">
                  Status
                </label>
                <select
                  className="form-control"
                  name="ins_status"
                  id="ins_status"
                  value={form.ins_status}
                  onChange={handleChange}
                  required
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowMyModal(false)}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  {isEditing ? "Update" : "Add"}
                </button>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Insurance;
