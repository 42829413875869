import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { logout, login, isLogin, getToken } from '../../auth';

function SelectMSO({ onChangeType, value }) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [defaultValue, setDefaultValue] = useState();

    useEffect(() => {
        // console.log('----SelectMSO----')
        // console.log(value['value'])
        // console.log(value)
        if (value) {
            setDefaultValue(value['value'])
        }
        getData()
    }, [onChangeType]);

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/mso`, {
            headers: {
                 'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                // console.log(res.data.data)
                const data = res.data.data.map((item) => ({
                    value: item.group_id,
                    label: item.group_code + ' : ' + item.group_name,
                }));

                data.push({
                    value: null,
                    label: 'None-MSO Customer',
                  });

                setOptions(data)

            })
            .catch(error => {
                console.log(error)
            })
    }


    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onChangeType(selectedOption)
        // console.log(selectedOption)
    };


    return (
        <Select
            // defaultValue={defaultValueTest}
            // value={selectedOption}

            onChange={handleSelectChange}
            options={options}
            value={options.find(option => option.value === defaultValue)}
            placeholder="Search..."
            isSearchable
        />
    );
}

export default SelectMSO;
