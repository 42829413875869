import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import validator from "validator";
import CurrencyInput from "react-currency-input-field";

import { logout, login, isLogin, getToken } from "../../auth";

const ValueChain_shopDetails = ({ cust_id, customer, onUpdated }) => {
  const [cust_sdtShopType, setCust_sdtShopType] = useState({
    value: customer["cust_sdtShopType"],
    label: customer["cust_sdtShopType"],
  });
  const [cust_sdtNumberOfSprayBooth, setCust_sdtNumberOfSprayBooth] = useState(
    customer["cust_sdtNumberOfSprayBooth"] || "0.00"
  );
  const [
    cust_sdtNumberOfPreparationBooth,
    setCust_sdtNumberOfPreparationBooth,
  ] = useState(customer["cust_sdtNumberOfPreparationBooth"] || "0.00");
  const [cust_sdtNumberOfWorkingBay, setCust_sdtNumberOfWorkingBay] = useState(
    customer["cust_sdtNumberOfWorkingBay"] || "0.00"
  );
  const [cust_sdtNumberOfBPStaff, setCust_sdtNumberOfBPStaff] = useState(
    customer["cust_sdtNumberOfBPStaff"] || "0.00"
  );
  const [cust_sdtNumberOfFrontStaff, setCust_sdtNumberOfFrontStaff] = useState(
    customer["cust_sdtNumberOfFrontStaff"] || "0.00"
  );

  // const [cust_sdtShopType, setCust_sdtShopType] = useState([])
  // const [cust_sdtNumberOfSprayBooth, setCust__sdtNumberOfSprayBooth] = useState([])
  // const [cust_sdtNumberOfPreparationBooth, setCust_sdtNumberOfPreparationBooth] = useState([])
  // const [cust_sdtNumberOfWorkingBay, setCust__sdtNumberOfWorkingBay] = useState([])
  // const [cust_sdtNumberOfBPStaff, setCust_sdtNumberOfBPStaff] = useState([])
  // const [cust_sdtNumberOfFrontStaff, etCust_sdtNumberOfFrontStaff] = useState([])

  const [formErrors, setFormErrors] = useState([]);

  const optionShopType = [
    { value: "พื้นที่เช่า", label: "พื้นที่เช่า" },
    { value: "พื้นที่ของตัวเอง", label: "พื้นที่ของตัวเอง" },
  ];

  const handleUpdated = (status) => {
    onUpdated(status);
    return status;
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (
      cust_sdtShopType["value"] === null ||
      cust_sdtShopType["value"] === undefined
    ) {
      errors.cust_sdtShopType = "ประเภทพื้นที่ is required";
    }

    if (
      cust_sdtNumberOfSprayBooth === undefined ||
      validator.equals(cust_sdtNumberOfSprayBooth.toString(), "0.00")
    ) {
      errors.cust_sdtNumberOfSprayBooth = "Number of Spray Booth is required";
    }
    if (
      cust_sdtNumberOfPreparationBooth === undefined ||
      validator.equals(cust_sdtNumberOfPreparationBooth.toString(), "0.00")
    ) {
      errors.cust_sdtNumberOfPreparationBooth =
        "Number of Preparation Booth is required";
    }

    if (
      cust_sdtNumberOfWorkingBay === undefined ||
      validator.equals(cust_sdtNumberOfWorkingBay.toString(), "0.00")
    ) {
      errors.cust_sdtNumberOfWorkingBay = "Number of Working Bay is required";
    }

    if (
      cust_sdtNumberOfBPStaff === undefined ||
      validator.equals(cust_sdtNumberOfBPStaff.toString(), "0.00")
    ) {
      errors.cust_sdtNumberOfBPStaff =
        "Number of BP Staff (ช่างแผนกต่างๆภายใน BP) is required";
    }
    if (
      cust_sdtNumberOfFrontStaff === undefined ||
      validator.equals(cust_sdtNumberOfFrontStaff.toString(), "0.00")
    ) {
      errors.cust_sdtNumberOfFrontStaff =
        "Number of Front Staff (SA / คนเปิดบิล) is required";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = {
        cust_sdtShopType: cust_sdtShopType["value"],
        cust_sdtNumberOfSprayBooth: cust_sdtNumberOfSprayBooth,
        cust_sdtNumberOfPreparationBooth: cust_sdtNumberOfPreparationBooth,
        cust_sdtNumberOfWorkingBay: cust_sdtNumberOfWorkingBay,
        cust_sdtNumberOfBPStaff: cust_sdtNumberOfBPStaff,
        cust_sdtNumberOfFrontStaff: cust_sdtNumberOfFrontStaff,
      };
      // console.log(con_id)
      // console.log(data)
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/value-chain-update/${cust_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          handleUpdated(true);
          Swal.fire({
            title: "Success!",
            text: res.data["message"],
            icon: "success",
            confirmButtonText: "OK",
          });
        })
        .catch((error) => {
          console.log(error);

          Swal.fire({
            title: error.message,
            text: "Please try again later",
            icon: "error",
          });
        });
    }
  };

  return (
    <>
      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">ประเภทพื้นที่</label>
        <div className="col-sm-4">
          <Select
            // defaultValue={{ label: '- Choose - ', value: 'null' }}
            // value={cust_sdtShopType}
            onChange={(e) => setCust_sdtShopType(e)}
            options={optionShopType}
            // value={cust_sdtShopType || ''}
            // value: customer['cust_sdtShopType'], label: customer['cust_sdtShopType']
            // value={cust_sdtShopType['value'] ? { label: customer['cust_sdtShopType'], value: customer['cust_sdtShopType'] } : null}
            value={cust_sdtShopType["value"] ? cust_sdtShopType : ""}
            placeholder="- Choose -"
            isSearchable
            className={` ${formErrors.cust_sdtShopType && `is-invalid `}`}
          />
          {formErrors.cust_sdtShopType && (
            <div className="form-control-feedback">
              {formErrors.cust_sdtShopType}
            </div>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">
          Number of Spray Booth
          <br />
          จำนวนห้องพ่นสี
        </label>
        <div className="col-sm-4">
          {/* <input
                        value={cust_sdtNumberOfSprayBooth}
                        onChange={e => setCust_sdtNumberOfSprayBooth(e.target.value)}
                        type="number"
                        className="form-control" /> */}

          <CurrencyInput
            id="input-cust_sdtNumberOfSprayBooth"
            name="input-cust_sdtNumberOfSprayBooth"
            className={`form-control ${
              formErrors.cust_sdtNumberOfSprayBooth && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={0}
            decimalScale={0}
            onFocus={handleFocus}
            value={cust_sdtNumberOfSprayBooth}
            onValueChange={(value, name) =>
              setCust_sdtNumberOfSprayBooth(value)
            }
          />

          {formErrors.cust_sdtNumberOfSprayBooth && (
            <div className="form-control-feedback">
              {formErrors.cust_sdtNumberOfSprayBooth}
            </div>
          )}
        </div>
      </div>

      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">
          Number of Preparation Booth
          <br />
          จำนวนห้อง หรือ พื้นที่เตรียมพื้น
        </label>
        <div className="col-sm-4">
          {/* <input
                        value={cust_sdtNumberOfPreparationBooth}
                        onChange={e => setCust_sdtNumberOfPreparationBooth(e.target.value)}
                        type="number"
                        className="form-control" /> */}

          <CurrencyInput
            id="input-cust_sdtNumberOfPreparationBooth"
            name="input-cust_sdtNumberOfPreparationBooth"
            className={`form-control ${
              formErrors.cust_sdtNumberOfPreparationBooth && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={0}
            decimalScale={0}
            onFocus={handleFocus}
            value={cust_sdtNumberOfPreparationBooth}
            onValueChange={(value, name) =>
              setCust_sdtNumberOfPreparationBooth(value)
            }
          />

          {formErrors.cust_sdtNumberOfPreparationBooth && (
            <div className="form-control-feedback">
              {formErrors.cust_sdtNumberOfPreparationBooth}
            </div>
          )}
        </div>
      </div>

      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">
          Number of Working Bay
          <br />
          จำนวนช่องซ่อม
        </label>
        <div className="col-sm-4">
          {/* <input
                        value={cust_sdtNumberOfWorkingBay}
                        onChange={e => setCust_sdtNumberOfWorkingBay(e.target.value)}
                        type="number"
                        className="form-control" /> */}
          <CurrencyInput
            id="input-cust_sdtNumberOfWorkingBay"
            name="input-cust_sdtNumberOfWorkingBay"
            className={`form-control ${
              formErrors.cust_sdtNumberOfWorkingBay && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={0}
            decimalScale={0}
            onFocus={handleFocus}
            value={cust_sdtNumberOfWorkingBay}
            onValueChange={(value, name) =>
              setCust_sdtNumberOfWorkingBay(value)
            }
          />

          {formErrors.cust_sdtNumberOfWorkingBay && (
            <div className="form-control-feedback">
              {formErrors.cust_sdtNumberOfWorkingBay}
            </div>
          )}
        </div>
      </div>

      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">
          Number of BP Staff
          <br />
          จำนวนช่างภายในอู่
        </label>
        <div className="col-sm-4">
          {/* <input
                        value={cust_sdtNumberOfBPStaff}
                        onChange={e => setCust_sdtNumberOfBPStaff(e.target.value)}
                        type="number"
                        className="form-control" /> */}
          <CurrencyInput
            id="input-cust_sdtNumberOfBPStaff"
            name="input-cust_sdtNumberOfBPStaff"
            className={`form-control ${
              formErrors.cust_sdtNumberOfBPStaff && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={0}
            decimalScale={0}
            onFocus={handleFocus}
            value={cust_sdtNumberOfBPStaff}
            onValueChange={(value, name) => setCust_sdtNumberOfBPStaff(value)}
          />
          {formErrors.cust_sdtNumberOfBPStaff && (
            <div className="form-control-feedback">
              {formErrors.cust_sdtNumberOfBPStaff}
            </div>
          )}
        </div>
      </div>

      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">
          Number of Front Staff
          <br />
          จำนวนพนักงานออฟฟิศ
        </label>
        <div className="col-sm-4">
          {/* <input
                        value={cust_sdtNumberOfFrontStaff}
                        onChange={e => setCust_sdtNumberOfFrontStaff(e.target.value)}
                        type="number"
                        className="form-control" /> */}
          <CurrencyInput
            id="input-cust_sdtNumberOfFrontStaff"
            name="input-cust_sdtNumberOfFrontStaff"
            className={`form-control ${
              formErrors.cust_sdtNumberOfFrontStaff && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={0}
            decimalScale={0}
            onFocus={handleFocus}
            value={cust_sdtNumberOfFrontStaff}
            onValueChange={(value, name) =>
              setCust_sdtNumberOfFrontStaff(value)
            }
          />
          {formErrors.cust_sdtNumberOfFrontStaff && (
            <div className="form-control-feedback">
              {formErrors.cust_sdtNumberOfFrontStaff}
            </div>
          )}
        </div>
      </div>

      <div className="row mb-3  ">
        <label className="col-sm-3 col-form-label"> </label>
        <div className="col-sm-4">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ValueChain_shopDetails;
