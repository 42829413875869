import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const ApiSearchSelectEmCust = ({ onChangeType, value }) => {

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [defaultValue, setDefaultValue] = useState();

  // const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    // Set default value when the component mounts
    const defaultOption = { value: null, label: 'No' };
    setSelectedOption(defaultOption);
  }, []);



  const fetchData = async (inputValue, callback) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/erp-emcust/${inputValue}`, {
        headers: {
           'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })

      const data = response.data.data;


      //   const options = data.map((item) => ({
      //     value: item.CustID,
      //     label: item.CustName,
      //   }));

      //   callback(options);
      // } catch (error) {
      //   console.error('API Request Error:', error);
      //   callback([]);
      // }

      const options = [
        { value: null, label: 'No' }, // Include the default option
        ...data.map((item) => ({
          value: item.CustID,
          // label: item.CustName,
          label: item.CustCode + ' / ' + item.CustName + ' [ ' + item.ShortName + ' ] ',
        })),
      ];

      callback(options);
    } catch (error) {
      console.error('API Request Error:', error);
      callback([{ value: null, label: 'No' }]); // Include the default option even on error
    }

  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChangeType(selectedOption)
    // console.log(selectedOption)
  };

  return (

    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={(inputValue, callback) => fetchData(inputValue, callback)}
      placeholder="Search..."
      value={selectedOption}
      onChange={handleSelectChange}

    />

  );
};

export default ApiSearchSelectEmCust;
