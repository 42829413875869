import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import LoadingOverLay from "../loading/LoadingOverLay";
import { Link } from "react-router-dom";


const CustomerSegment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMyModal, setShowMyModal] = useState(false);
  const [segments, setSegments] = useState([]);
  const [form, setForm] = useState({
    segmentID: "",
    segmentName: "",
    segmentStatus: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchSegments();
  }, []);

  const fetchSegments = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/setup-dbsegment`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSegments(res.data);
    } catch (error) {
      console.error("Error fetching customer segments", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      if (isEditing) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/setup-dbsegment-update/${form.segmentID}`,
          {
            segmentName: form.segmentName,
            segmentStatus: form.segmentStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        Swal.fire({
          title: "Update successful",
          icon: "success",
        });
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/setup-dbsegment-create`,
          {
            segmentName: form.segmentName,
            segmentStatus: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        Swal.fire({
          title: "Save successful",
          icon: "success",
        });
      }

      setIsLoading(false);
      setShowMyModal(false);
      setForm({
        segmentID: "",
        segmentName: "",
        segmentStatus: "",
      });
      setIsEditing(false);
      fetchSegments();
    } catch (error) {
      setIsLoading(false);
      console.error("Error saving customer segment", error);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
      });
    }
  };

  const handleEdit = (segment) => {
    setForm({
      segmentID: segment.segmentID,
      segmentName: segment.segmentName,
      segmentStatus: segment.segmentStatus.toString(),
    });
    setIsEditing(true);
    setShowMyModal(true);
  };

  const handleDelete = async (segmentID) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/segments/${segmentID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchSegments();
    } catch (error) {
      console.error("Error deleting customer segment", error);
    }
  };

  return (
    <>
      {isLoading && <LoadingOverLay />}

      <div className="pagetitle">
        <h1>Customer Segment </h1>
        <nav>
          <ol className="breadcrumb">
          <li className="breadcrumb-item">
              <Link to="/setup/main">
                <span> Set Up</span>
              </Link>
            </li>
            <li className="breadcrumb-item active">
              Customer Segment 
            </li>
          </ol>
        </nav>
      </div>

      <div className="card">
        <div className="row pt-3 p-3 text-end">
          <div className="col-md-9"></div>
          <div className="col-md-2 text-end"></div>
          <div className="col-md-1">
            <div
              className="text-end cursor-pointer"
              onClick={() => setShowMyModal(true)}
            >
              <div className="icon">
                <i
                  className="bx bxs-folder-plus"
                  style={{ fontSize: "40px" }}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <h5 className="card-title d-none">Customer Segment Lists</h5>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Segment Name</th>
                <th>Status</th>
                <th className="d-none">Creation Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {segments.map((segment, key) => (
                <tr key={segment.segmentID} className={segment.segmentStatus === 1 ? " " : "text-danger"}>
                  <td>{key + 1}</td>
                  <td>{segment.segmentName}</td>
                  <td>{segment.segmentStatus === 1 ? "Active" : "Inactive"}</td>
                  <td className="d-none">{segment.setmentCreateDate}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      onClick={() => handleEdit(segment)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger d-none"
                      onClick={() => handleDelete(segment.segmentID)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        centered
        backdrop="static"
        size="lg"
        show={showMyModal}
        onHide={() => setShowMyModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Segment" : "Add Segment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="segmentName" className="form-label">
                Name
              </label>
              <input
                type="text"
                name="segmentName"
                id="segmentName"
                placeholder="Name"
                value={form.segmentName}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="segmentStatus" className="form-label">
                Status
              </label>
              <select
                className="form-control"
                name="segmentStatus"
                id="segmentStatus"
                value={form.segmentStatus}
                onChange={handleChange}
                required
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowMyModal(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                {isEditing ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerSegment;
