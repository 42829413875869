import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import validator from "validator";
// import CurrencyFormat from "react-currency-format";
import CurrencyInput from "react-currency-input-field";
// import { logout, login, isLogin, getToken } from '../../auth';

const ValueChain_demandPotential = ({ cust_id, customer, onUpdated }) => {
  const [cust_numberOfCarRepair, setCust_numberOfCarRepair] = useState(
    customer["cust_numberOfCarRepair"] || "0.00"
  );
  const [cust_totalPaint, setCust_totalPaint] = useState(
    customer["cust_totalPaint"] || "0.00"
  );
  const [cust_brcSales, setCust_brcSales] = useState(
    customer["cust_brcSales"] || "0.00"
  );
  const [cust_laborRate, setCust_laborRate] = useState(
    customer["cust_laborRate"] || "0.00"
  );

  const [formErrors, setFormErrors] = useState([]);
  const [percent, setPercent] = useState(0);
  // {cust_totalPaint / cust_laborRate }%

  useEffect(() => {
    // if (cust_id) {

    // //     // console.log(customer)
    // //     // console.log(customer['cust_totalPaint'])
    // console.log(isUpdate)
    // }
    // // // console.log('d')

    setPercent(((cust_totalPaint / cust_laborRate) * 100).toFixed(2));
  }, [cust_totalPaint, cust_laborRate]);

  const handleUpdated = (status) => {
    onUpdated(status);
    return status;
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (
      cust_numberOfCarRepair === undefined ||
      validator.equals(cust_numberOfCarRepair.toString(), "0.00")
    ) {
      errors.cust_numberOfCarRepair =
        "Number of Car Repair (Car/month) is required (number)";
    }

    if (
      cust_totalPaint === undefined ||
      validator.equals(cust_totalPaint.toString(), "0.00")
    ) {
      errors.cust_totalPaint = "Total Demand (THB/month) is required (number)";
    }

    if (
      cust_brcSales === undefined ||
      validator.equals(cust_brcSales.toString(), "0.00")
    ) {
      errors.cust_brcSales = "Current Sales (THB/month) is required (number)";
    }

    if (
      cust_laborRate === undefined ||
      validator.equals(cust_laborRate.toString(), "0.00")
    ) {
      errors.cust_laborRate = "Paint Cost/Labor Rate (%) is required (number)";
    }

    console.log(cust_brcSales);

    console.log(errors);

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = {
        cust_numberOfCarRepair: cust_numberOfCarRepair,
        cust_totalPaint: cust_totalPaint,
        cust_brcSales: cust_brcSales,
        cust_laborRate: cust_laborRate,
        cust_demandPotentialLastUpdated: new Date(),
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/value-chain-update/${cust_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res)
          handleUpdated(true);
          Swal.fire({
            title: "Success!",
            text: res.data["message"],
            icon: "success",
            confirmButtonText: "OK",
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: error.message,
            text: "Please try again later",
            icon: "error",
          });
        });
    }
  };

  return (
    <>
      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">Number of Car Repair </label>
        <div className="col-sm-6">
          <CurrencyInput
            value={cust_numberOfCarRepair}
            id="input-cust_numberOfCarRepair"
            name="input-cust_numberOfCarRepair"
            className={`form-control text-right ${
              formErrors.cust_numberOfCarRepair && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={2}
            decimalScale={0}
            onFocus={handleFocus}
            onValueChange={(value, name) => setCust_numberOfCarRepair(value)}
          />

          {formErrors.cust_numberOfCarRepair && (
            <div className="form-control-feedback">
              {formErrors.cust_numberOfCarRepair}
            </div>
          )}
        </div>
        <div className="col-sm-3">Car(s)/month</div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">Paint Cost </label>
        <div className="col-sm-6">
          <CurrencyInput
            value={cust_totalPaint}
            id="input-cust_totalPaint"
            name="input-cust_totalPaint"
            className={`form-control text-right ${
              formErrors.cust_totalPaint && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={2}
            decimalScale={0}
            onFocus={handleFocus}
            onValueChange={(value, name) => setCust_totalPaint(value)}
          />
          {formErrors.cust_totalPaint && (
            <div className="form-control-feedback">
              {formErrors.cust_totalPaint}
            </div>
          )}
        </div>
        <div className="col-sm-3">THB/month </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">BRC Sales Amount</label>
        <div className="col-sm-6">
          <CurrencyInput
            value={cust_brcSales}
            id="input-cust_brcSales"
            name="input-cust_brcSales"
            className={`form-control text-right ${
              formErrors.cust_brcSales && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={2}
            decimalScale={0}
            onFocus={handleFocus}
            onValueChange={(value, name) => setCust_brcSales(value)}
          />
          {formErrors.cust_brcSales && (
            <div className="form-control-feedback">
              {formErrors.cust_brcSales}
            </div>
          )}
        </div>
        <div className="col-sm-3">THB/month </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">Labor Rate</label>
        <div className="col-sm-6">
          <CurrencyInput
            value={cust_laborRate}
            id="input-cust_laborRate"
            name="input-cust_laborRate"
            className={`form-control text-right ${
              formErrors.cust_laborRate && `is-invalid `
            }`}
            placeholder=""
            defaultValue={0}
            decimalsLimit={2}
            decimalScale={0}
            onFocus={handleFocus}
            // onValueChange={(value, name) => console.log(value, name)}
            onValueChange={(value, name) => setCust_laborRate(value)}
          />
          {formErrors.cust_laborRate && (
            <div className="form-control-feedback">
              {formErrors.cust_laborRate}
            </div>
          )}
        </div>
        <div className="col-sm-3">THB/month </div>
      </div>

      <div className="row mb-3">
        <label className="col-sm-3 col-form-label">Paint Cost/Labor Rate</label>
        <div className="col-sm-6">{percent}%</div>
      </div>

      <div className="row mb-3">
        <label className="col-sm-9 col-form-label g-font-size-12">
          Last Updated:{" "}
          {new Date(customer.cust_demandPotentialLastUpdated)
            .toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
            .replace(",", " /")}
        </label>
      </div>

      <div className="row mb-3  ">
        <label className="col-sm-3 col-form-label"> </label>
        <div className="col-sm-10">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ValueChain_demandPotential;
