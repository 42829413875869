import React, { useEffect, useState } from 'react';
import { Modal, Button, Overlay } from 'react-bootstrap';
import axios from "axios";
import Loading from "../loading/Loading";
import { Link } from "react-router-dom";


const MyModal = ({ group_name, group_id, showModal, handleClose }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [cust, setCust] = useState([])

  useEffect(() => {
    if (group_id) {
      setCust([])
      getData()
    }
  }, [group_id, handleClose])

  const getData = () => {
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_API_URL}/mso-detail-cust/${group_id}`, {
      headers: {
        // 'Authorization': `${process.env.REACT_APP_API_KEY}`,
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.data.data) {
          // console.log(res.data.data)
          const filtered = res.data.data.filter((item1) => {
            return item1.cust_status != 'D';
          });
          // console.log(filtered);
          setCust(filtered)
        }

        setIsLoading(false)

      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)

      })
  }


  return (
    <Modal
    centered
      backdrop="static"
      size="lg"
      show={showModal}
      onHide={handleClose}>
        
      <Modal.Header closeButton>
        <Modal.Title className=' '>{group_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {isLoading ?
          (
            <>
              <Loading />
            </>
          ) : (
            <>
              <table className="table text-dark-blue">
                <tbody>
                  {cust.length > 0 ? (

                    cust.map((item, key) => (

                      <tr key={key} className='mouse-pointer trHover' >
                        <td>{key + 1}</td>
                        <td>
                          <Link to={`/value-chain-detail/${item['cust_id']}`}>
                          {item['custCode']} /  {item['custName']}
                          </Link>
                        </td>
                        <td> </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className=' text-center text-black g-font-size-16'>Data not found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
      </Modal.Body>

    </Modal>
  );
};

export default MyModal;
