import React, { useEffect, useState } from "react";
import axios from "axios";
// import Select from 'react-select';
import Swal from "sweetalert2";
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import validator from "validator";
import CurrencyInput from 'react-currency-input-field';

import { logout, login, isLogin, getToken } from '../../auth';


const ValueChain_repair = ({ cust_id, customer, onUpdated }) => {

    const [cust_reNumberofLigthRepair1_3panels, setCust_reNumberofLigthRepair1_3panels] = useState(customer['cust_reNumberofLigthRepair1_3panels'] || '0.00')
    const [cust_reNumberofMediumRepair4_7panels, setCust_reNumberofMediumRepair4_7panels] = useState(customer['cust_reNumberofMediumRepair4_7panels'] || '0.00')
    const [cust_reNumberofHeavyRepairMore8panels, setCust_reNumberofHeavyRepairMore8panels] = useState(customer['cust_reNumberofHeavyRepairMore8panels'] || '0.00')
    const [cust_paintCostLaborRate, setCust_paintCostLaborRate] = useState(customer['cust_paintCostLaborRate'])
    const [test, setTest] = useState()

    const [formErrors, setFormErrors] = useState([])

    const calculateSum = () => {
        return parseFloat(cust_reNumberofLigthRepair1_3panels, 2) + parseFloat(cust_reNumberofMediumRepair4_7panels, 2) + parseFloat(cust_reNumberofHeavyRepairMore8panels, 2);
    };

    const handleUpdated = (status) => {
        onUpdated(status);
        return status;
    };


    const handleFocus = (event) => {
        event.target.select();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = {};
        if (cust_reNumberofLigthRepair1_3panels === undefined || validator.equals(cust_reNumberofLigthRepair1_3panels.toString(), '0.00')) {
            errors.cust_reNumberofLigthRepair1_3panels = 'Percentage of  Light Repair  (1-3 panels)';
        }
        if (cust_reNumberofMediumRepair4_7panels === undefined || validator.equals(cust_reNumberofMediumRepair4_7panels.toString(), '0.00')) {
            errors.cust_reNumberofMediumRepair4_7panels = 'Percentage of Medium Repair (4-7 panels)';
        }
        if (cust_reNumberofHeavyRepairMore8panels === undefined || validator.equals(cust_reNumberofHeavyRepairMore8panels.toString(), '0.00')) {
            errors.cust_reNumberofHeavyRepairMore8panels = 'Percentage of Heavy Repair (≥8 panels)';
        }


        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const data = {
                'cust_reNumberofLigthRepair1_3panels': cust_reNumberofLigthRepair1_3panels,
                'cust_reNumberofMediumRepair4_7panels': cust_reNumberofMediumRepair4_7panels,
                'cust_reNumberofHeavyRepairMore8panels': cust_reNumberofHeavyRepairMore8panels,
            }

            axios.put(`${process.env.REACT_APP_API_URL}/value-chain-update/${cust_id}`, data,
                {
                    headers: {
                         'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => {
                    handleUpdated(true)
                    Swal.fire({
                        title: 'Success!',
                        text: res.data['message'],
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })


                })
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        title: error.message,
                        text: 'Please try again later',
                        icon: 'error',
                    })
                })
        }
    }

    const sum = calculateSum();

    return (
        <>
            <div className="row mb-3">
                <label className="col-sm-4 col-form-label">Percentage of  Light Repair  (1-3 panels)
                <br/>งานประเภทซ่อมเบา (1-3 ชิ้น)
                </label>
                <div className="col-sm-4">
                    {/* <input
                        value={cust_reNumberofLigthRepair1_3panels}
                        onChange={e => setCust_reNumberofLigthRepair1_3panels(e.target.value)}
                        type="number"
                        className="form-control" /> */}
                    <CurrencyInput
                        value={cust_reNumberofLigthRepair1_3panels}
                        id="input-cust_reNumberofLigthRepair1_3panels"
                        name="input-cust_reNumberofLigthRepair1_3panels"
                        className={`form-control text-right ${formErrors.cust_reNumberofLigthRepair1_3panels && `is-invalid `}`}
                        placeholder=""
                        defaultValue={0}
                        decimalsLimit={0}
                        decimalScale={0}
                        onFocus={handleFocus}
                        // onValueChange={(value, name) => console.log(value, name)}
                        onValueChange={(value, name) => setCust_reNumberofLigthRepair1_3panels(value)}
                    />

                    {formErrors.cust_reNumberofLigthRepair1_3panels && <div className="form-control-feedback">{formErrors.cust_reNumberofLigthRepair1_3panels}</div>}

                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-4 col-form-label">Percentage of Medium Repair (4-7 panels)
                <br/>งานประเภทซ่อมกลาง (4-7 ชิ้น)


                </label>
                <div className="col-sm-4">
                    {/* <input
                        value={cust_reNumberofMediumRepair4_7panels}
                        onChange={e => setCust_reNumberofMediumRepair4_7panels(e.target.value)}
                        type="number"
                        className="form-control" /> */}
                    <CurrencyInput
                        value={cust_reNumberofMediumRepair4_7panels}
                        id="input-cust_reNumberofMediumRepair4_7panels"
                        name="input-cust_reNumberofMediumRepair4_7panels"
                        className={`form-control text-right ${formErrors.cust_reNumberofMediumRepair4_7panels && `is-invalid `}`}
                        placeholder=""
                        defaultValue={0}
                        decimalsLimit={0}
                        decimalScale={0}
                        onFocus={handleFocus}
                        // onValueChange={(value, name) => console.log(value, name)}
                        onValueChange={(value, name) => setCust_reNumberofMediumRepair4_7panels(value)}
                    />
                    {formErrors.cust_reNumberofMediumRepair4_7panels && <div className="form-control-feedback">{formErrors.cust_reNumberofMediumRepair4_7panels}</div>}

                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-4 col-form-label">Percentage of Heavy Repair (≥8 panels)
                <br/>งานประเภทซ่อมหนัก (มากกว่าหรือเท่ากับ 8 ชิ้น)


                </label>
                <div className="col-sm-4">
                    {/* <input
                        value={cust_reNumberofHeavyRepairMore8panels}
                        onChange={e => setCust_reNumberofHeavyRepairMore8panels(e.target.value)}
                        type="number"
                        className="form-control" /> */}
                    <CurrencyInput
                        value={cust_reNumberofHeavyRepairMore8panels}
                        id="input-cust_reNumberofHeavyRepairMore8panels"
                        name="input-cust_reNumberofHeavyRepairMore8panels"
                        className={`form-control text-right ${formErrors.cust_reNumberofHeavyRepairMore8panels && `is-invalid `}`}
                        placeholder=""
                        defaultValue={0}
                        decimalsLimit={0}
                        decimalScale={0}
                        onFocus={handleFocus}
                        // onValueChange={(value, name) => console.log(value, name)}
                        onValueChange={(value, name) => setCust_reNumberofHeavyRepairMore8panels(value)}
                    />
                    {formErrors.cust_reNumberofHeavyRepairMore8panels && <div className="form-control-feedback">{formErrors.cust_reNumberofHeavyRepairMore8panels}</div>}

                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-4 col-form-label"> </label>
                <div className="col-sm-4 text-right">
                    <div>
                        {sum} %
                    </div>
                    <div className="g-font-size-12 text-danger">
                        * อัตราส่วน % ต้องเท่ากับ 100% เท่านั้น
                    </div>
                </div>
            </div>



            <div className="row mb-3  ">
                <label className="col-sm-4 col-form-label"> </label>
                <div className="col-sm-4">
                    <button type="submit"
                        className={`btn btn-primary ${sum !== 100 && `disabled`} `}
                        onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>
        </>
    )
}

export default ValueChain_repair;