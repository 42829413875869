// https://github.com/sreenathkspanikker/react-private-public-routes

// LOGIN
export const login = () => {
    // if (d.username === 'user' && d.password === 'password') {
    //     localStorage.setItem('auth', d)
    //     props.history.push('/home');
    //     message.success('Login Success')
    // }
    // else {
    //     message.error('Login Failed')
    // }
    return 'login'
}

// LOGOUT
export const logout = () => {
    // localStorage.removeItem('auth')
    // message.success('Logout Success')
    return 'logout'
}

// LOGIN STATUS
export const isLogin = () => {
    if (localStorage.getItem('token')) return true;
    return false;

    // return 'isLogin'
}

export const getToken = ()   => {
    const token = localStorage.getItem('token');

    // return   localStorage.getItem('token') ;

    if (token) return token;
    return false;


}