import React, { useState, useEffect } from "react";
// import API from '../../config.json'
import profile from "../../assets/picture/profile-img.png";
import Logo from "../../assets/picture/logo.png";
import { Link } from "react-router-dom";
// import DarkMode from "../darkmode/DarkMode";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const navigate = useNavigate();

  // const userToken = localStorage.getItem('userToken');
  // const [crmTotalPlan, setCrmTotalPlan] = useState(0)
  const fname = localStorage.getItem("fname");
  const lname = localStorage.getItem("lname");
  // const picture = localStorage.getItem("picture");
  // const picture_path = localStorage.getItem("picture_path");/
  function toggleSidebarBtn() {
    if (document.body.classList.contains("toggle-sidebar")) {
      document.body.classList.remove("toggle-sidebar");
    } else {
      document.body.classList.add("toggle-sidebar");
    }
  }
  function toggleSidebarBtnMobile() {
    if (window.screen.width <= 900) {
      if (document.body.classList.contains("toggle-sidebar")) {
        document.body.classList.remove("toggle-sidebar");
      } else {
        document.body.classList.add("toggle-sidebar");
      }
    }
  }

  const logOut = () => {
    Swal.fire({
      title: "Ate you sure you want to sign out?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        localStorage.clear();
        Swal.fire("logout successful !", "", "success");
        navigate("/");
        navigate(0);
      }
    });
  };

  return (
    <div>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/profile" className="logo d-flex align-items-center">
            <img src={Logo} />
            <span className="d-none d-lg-block">NND</span>
          </Link>
          <i
            className="bi bi-list toggle-sidebar-btn"
            id="toggle-sidebar-btn"
            onClick={toggleSidebarBtn}
          ></i>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown">
              <a className="nav-link nav-icon"></a>
            </li>

            <li className="nav-item dropdown pe-3">
              <div
                className="nav-link nav-profile d-flex align-items-center pe-0"
                data-bs-toggle="dropdown"
              >
                <img src={profile} className="rounded-circle" />
                {/* <span className="d-none d-md-block dropdown-toggle ps-2 ">{fname + ' ' + lname}</span> */}
              </div>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to="/profile"
                  >
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#icons-nav"
              data-bs-toggle="collapse"
              href="#"
              aria-expanded="false"
            >
              <i className="bi bi-gem"></i>
              <span>NND SYS</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>

            <ul
              id="icons-nav"
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="setup/main">
                  <i className="bi bi-circle"></i>
                  <span> Set Up</span>
                </Link>
              </li>

              <li>
                <Link to="/mso">
                  <i className="bi bi-circle"></i>
                  <span>MSO Customer</span>
                </Link>
              </li>

              <li>
                <Link to="/value-chain-none-mso">
                  <i className="bi bi-circle"></i>
                  <span> Non-MSO Customer</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item ">
            <Link
              className="nav-link collapsed"
              onClick={toggleSidebarBtnMobile}
              to="/profile"
            >
              <i className=" ri-user-3-fill"></i>
              <span>Profile</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" onClick={logOut}>
              <i className=" ri-user-shared-fill"></i>
              <span>Logout</span>
            </Link>
          </li>

          <li className="nav-item g-font-size-10 text-center">
            Build: {`${process.env.REACT_APP_VERSION}`}
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Menu;
