import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../axiosInstance";

import ValueChain_Info from "./ValueChain_Info";
import ValueChain_salesProcess from "./ValueChain_salesProcess";
import ValueChain_contactRole from "./ValueChain_contactRole";
import ValueChain_demandPotential from "./valueChain_demandPotential";
import ValueChain_insurance from "./ValueChain_insurance";
import ValueChain_repair from "./ValueChain_repair";
import ValueChain_vas from "./ValueChain_vas";
import ValueChain_shopDetails from "./ValueChain_shopDetails";
import ValueChain_productMix from "./ValueChain_productMix";
import ValueChain_activities from "./ValueChain_activities";
import ValueChain_painPointValueProposition from "./ValueChain_painPointValueProposition";
import ValueChain_photo from "./ValueChain_photo";
// import LoadingOverlay from "../loading/LoadingOverLay";

import LoadingSkeleton from "../loading/LoadingSkeleton";

// import { logout, login, isLogin, getToken } from '../../auth';

import "./Style.css";

const ValueChainDetail = () => {
  const params = useParams();
  const [cust_id, setCust_id] = useState(params.cust_id);
  const [showComponent, setShowComponent] = useState(); //valueChain
  const [customer, setCustomer] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false); /// สำหรับตรวจสอบว่า companent มีนั้นๆมีอัพเดทไหม

  const [paintSystemData, setPaintSystemData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [farmingData, setFarmingData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getCustomer();
  }, [isUpdated]);

  useEffect(() => {
    // clickShowComponent("valueChain");
    clickShowComponent("valueChain");

    

  }, []);

  const clickShowComponent = (compomentName) => {
    setShowComponent(compomentName);
  };

  const isUpdate = (status) => {
    setIsUpdated(status);
  };

  const getCustomer = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/value-chain-detail/${cust_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data.data) {
        setError("No Data Found");
      } else {
        setCustomer(response.data.data);
        // console.log(response.data.data);
        setCust_id(response.data.data["cust_id"]);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const menu = [
    {
      id: 0,
      name: "Data Base",
      component: "valueChain",
    },
    {
      id: 11,
      name: "Sales Process",
      component: "salesProcess",
    },

    {
      id: 1,
      name: "Power Map",
      component: "contactRole",
    },
    {
      id: 2,
      name: "Demand Potential",
      component: "demandPotential",
    },
    {
      id: 3,
      name: "Insurance",
      component: "insurance",
    },
    {
      id: 4,
      name: "Repair Type",
      component: "repair",
    },
    {
      id: 5,
      name: "Shop Details",
      component: "shopDetails",
    },
    {
      id: 6,
      name: "Product Mix",
      component: "productMix",
    },
    {
      id: 7,
      name: "VAS",
      component: "VAS",
    },
    {
      id: 8,
      name: "Pain Point & Value Proposition",
      component: "painPointValueProposition",
    },
    {
      id: 9,
      name: "Activities",
      component: "activities",
    },
    {
      id: 10,
      name: "Photo",
      component: "photo",
    },
  ];

  if (error) {
    return (
      <>
        {" "}
        <div
          className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show"
          role="alert"
        >
          {error}
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <LoadingSkeleton />
      </>
    );
  }

  return (
    <section className="dashboard">
      <div className="pagetitle">
        <h1>{customer["CustCode"] + " / " + customer["CustShortName"]}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Value Chain</a>
            </li>
            <li className="breadcrumb-item active">Detail</li>
          </ol>
        </nav>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title d-none">
            {customer["CustCode"] + " / " + customer["CustName"]}
          </h3>

          <div className="d-flex align-items-start g-pt-50">
            <div
              className="nav flex-column nav-pills me-3 w-20 border-end p-3 bg-menu radio "
              _style={{ borderRight: "1px solid #ced4da", padding: "10px" }}
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              {menu.map((item, key) => (
                <button
                  key={key}
                  className={`${item.component} ' nav-link text-left ${
                    item.component == showComponent ? "active" : ""
                  }`}
                  style={{ fontSize: "16px", fontWeight: "500" }}
                  id={item.link}
                  data-bs-toggle="pill"
                  // data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  // aria-controls={item.link}
                  aria-selected="false"
                  // tabIndex="-1"
                  onClick={() => clickShowComponent(item.component)}
                >
                  {item.name}
                </button>
              ))}
            </div>
            <div
              className="tab-content w-100 pl-3"
              id="v-pills-tabContent"
              style={{ minHeight: "400px" }}
            >
              <h5 className="card-title d-none">{showComponent}</h5>
              {showComponent === "valueChain" && (
                <ValueChain_Info
                  cust_id={cust_id}
                  customer={customer}
                  onUpdated={isUpdate}
                />
              )}
              {showComponent === "salesProcess" && (
                <ValueChain_salesProcess
                  cust_id={cust_id}
                  customer={customer}
                  onUpdated={isUpdate}
                />
              )}

              {showComponent === "contactRole" && (
                <ValueChain_contactRole cust_id={cust_id} />
              )}
              {showComponent === "demandPotential" && (
                <ValueChain_demandPotential
                  cust_id={cust_id}
                  customer={customer}
                  onUpdated={isUpdate}
                />
              )}
              {showComponent === "insurance" && (
                <ValueChain_insurance cust_id={cust_id} />
              )}
              {showComponent === "repair" && (
                <ValueChain_repair
                  cust_id={cust_id}
                  customer={customer}
                  onUpdated={isUpdate}
                />
              )}
              {showComponent === "VAS" && (
                <ValueChain_vas
                  cust_id={cust_id}
                  customer={customer}
                  onUpdated={isUpdate}
                />
              )}
              {showComponent === "shopDetails" && (
                <ValueChain_shopDetails
                  cust_id={cust_id}
                  customer={customer}
                  onUpdated={isUpdate}
                />
              )}
              {showComponent === "productMix" && (
                <ValueChain_productMix cust_id={cust_id} />
              )}
              {showComponent === "activities" && (
                <ValueChain_activities cust_id={cust_id} />
              )}
              {showComponent === "painPointValueProposition" && (
                <ValueChain_painPointValueProposition cust_id={cust_id} />
              )}
              {showComponent === "photo" && (
                <ValueChain_photo cust_id={cust_id} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValueChainDetail;
