import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Swal from "sweetalert2";
import PrivateRoute from "./PrivateRoute";

// import logo from './logo.svg';
import "./App.css";
import NotFound from "./component/notFound/NotFound";
import Menu from "./component/menu/Menu";
import Profile from "./component/profile/Profile";
import Login from "./component/login/LoginBasic";
import LoginJWT from "./component/login/Login";
import ForgotPassword from "./component/forgotpassword/Forgotpassword";

import Logout from "./component/logout/Logout";
import HomeMSO from "./component/mso/Home";
import Home from "./component/mso/Home";

import ValueChain from "./component/valueChain/valueChain";
import ValueChainDetail from "./component/valueChain/valueChainDetail";
import ValueChainCreate from "./component/valueChain/valueChainCreate";
import NoneMSOValueChainCreate from "./component/valueChain/NoneMSOValueChainCreate";
import NoneMSOvalueChain from "./component/valueChain/NoneMSOvalueChain";

import RouteGuard from "./RouteGuard";

import { isLogin } from "./auth";

///// setup
import Main from "./component/setup/Main";
import Insurance from "./component/setup/Insurance";
import Farming from "./component/setup/Farming";
import CustomerSegment from "./component/setup/CustomerSegment";
import PaintSystem from "./component/setup/PaintSystem";
import ProductMix from "./component/setup/ProductMix";

import SalesProcess from "./component/setup/SalesProcess";
import SalesProcessDT from "./component/setup/SalesProcessDT";

import PowerMapRole from "./component/setup/PowerMapRole";
import PowerMapRelationship from "./component/setup/PowerMapRelationship";
 
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const lastBuildVersion = localStorage.getItem("lastBuildVersion");
    if (
      lastBuildVersion &&
      lastBuildVersion !== process.env.REACT_APP_VERSION
    ) {
      Swal.fire({
        title: "New Version Available",
        text: "A new version of the application is available. Clearing cache to load the latest version.",
        icon: "info",
        showConfirmButton: false,
        timer: 3000,
      });
      localStorage.clear();
      localStorage.setItem("lastBuildVersion", process.env.REACT_APP_VERSION);
      window.location.reload(true);
    } else {
      localStorage.setItem("lastBuildVersion", process.env.REACT_APP_VERSION);
    }

    if (isLogin()) {
      setIsLoggedIn(true);
      // window.location.href="/profile"
    }
  }, []);

  return (
    <BrowserRouter>
      {isLoggedIn ? <Menu /> : <></>}

      <Routes>
        <Route path="/" exact element={<LoginJWT />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/logout" exact element={<Logout />} />
        {/* <Route path="/LoginJWT" exact element={<LoginJWT />} /> */}
        <Route path="/forgotpassword" exact element={<ForgotPassword />} />

        <Route path="/profile" element={<PrivateRoute component={Profile} />} />
        <Route path="/mso" element={<PrivateRoute component={HomeMSO} />} />
        <Route
          path="/value-chain"
          element={<PrivateRoute component={ValueChain} />}
        />
        <Route
          path="/value-chain-detail/:cust_id"
          element={<PrivateRoute component={ValueChainDetail} />}
        />
        {/* <Route
          path="/value-chain-create"
          element={<PrivateRoute component={ValueChainCreate} />}
        /> */}

        <Route
          path="/value-chain-create-none-mso"
          element={<PrivateRoute component={NoneMSOValueChainCreate} />}
        />

        <Route
          path="/value-chain-none-mso"
          element={<PrivateRoute component={NoneMSOvalueChain} />}
        />

        {/* setup */}
        <Route path="/setup/main" exact element={<Main />} />
        <Route path="/setup/insurance" exact element={<Insurance />} />
        <Route path="/setup/farming" exact element={<Farming />} />
        <Route
          path="/setup/customer-segment"
          exact
          element={<CustomerSegment />}
        />
        <Route path="/setup/paintsystem" exact element={<PaintSystem />} />
        <Route path="/setup/productmix" exact element={<ProductMix />} />
        <Route path="/setup/salesProcess" exact element={<SalesProcess />} />
        <Route
          path="/setup/salesProcess-dt"
          exact
          element={<SalesProcessDT />}
        />

        <Route path="/setup/powermap-role" exact element={<PowerMapRole />} />
        <Route path="/setup/powermap-relationship" exact element={<PowerMapRelationship />} />

        {/* <Route path="/profile" exact element={<Profile />} /> */}
        {/* <PrivateRoute path="/profile" element={<Profile />} /> */}
        {/* <Route path="/logout" exact element={<Logout />} />
        <Route path="/mso" element={<HomeMSO />} />
        <Route path="/value-chain" element={<ValueChain />} />
        <Route path="/value-chain-detail/:cust_id" element={<ValueChainDetail />} />
        <Route path="/value-chain-create" element={<ValueChainCreate />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
