import React from "react";
import { Link } from "react-router-dom";

const Main = () => {
  return (
    <>
      <div className="pagetitle">
        <h1> Management</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/setup/main">
                <span> Management</span>
              </Link>
            </li>
            <li className="breadcrumb-item active"> Main</li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">  </h5>

            <ul class="list-group">
              <li class="list-group-item">
                <Link to="/setup/insurance">
                   <span> Insurance  </span>
                </Link>
              </li>

              <li class="list-group-item">
                <Link to="/setup/farming">
                   <span> Farming  </span>
                </Link>
              </li>

              <li class="list-group-item">
                <Link to="/setup/customer-segment">
                   <span> Customer Segment  </span>
                </Link>
              </li>

              <li class="list-group-item">
                <Link to="/setup/paintsystem">
                   <span> Paint System  </span>
                </Link>
              </li>

              
              <li class="list-group-item">
                <Link to="/setup/productmix">
                   <span> ProductMix  </span>
                </Link>
              </li>

              
              <li class="list-group-item">
                <Link to="/setup/salesprocess">
                   <span> Sales Process </span>
                </Link>
              </li>

              <li class="list-group-item">
                <Link to="/setup/salesprocess-dt">
                   <span> Sales Process - เหตุผลที่ปิดการขาย  </span>
                </Link>
              </li>

              <li class="list-group-item">
                <Link to="/setup/powermap-role">
                   <span> Power Map - Role </span>
                </Link>
              </li>
 

              <li class="list-group-item">
                <Link to="/setup/powermap-relationship">
                   <span> Power Map - Relationship </span>
                </Link>
              </li>



            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
