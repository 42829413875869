import React, { useState, useEffect } from "react"
import axios from "axios";
import profile from "../../assets/picture/profile-img.png"

import Swal from "sweetalert2";

const Profile = () => {

    const userToken = localStorage.getItem('token');
    const fname = localStorage.getItem('fname');
    const lname = localStorage.getItem('lname');
    const [data, setDate] = useState([])
    const [file, setFile] = useState(null)

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');


    const [error, setError] = useState();

    useEffect(() => {
        getProfile();

    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'newPassword') {
            setNewPassword(value);

            if (value.length < 8) {
                setMessage('Password must be at least 8 characters long');
            } else {
                setMessage('');
            }
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };


    const handleSubmitChangePassword = async (e) => {
        e.preventDefault();

        // Perform validation
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }
        setMessage('')
        const data = { 'newPassword': newPassword }

        axios.post(`${process.env.REACT_APP_API_URL}/user-changepassword`, data, {
            headers: {
                // 'Authorization': `${process.env.REACT_APP_API_KEY}`,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                // console.log(response)
                if (response.status === 200) {
                    // setMessage(response.data.message);
                    Swal.fire({
                        title: response.data.message,
                        text: '',
                        icon: 'success',
                    })
                    setNewPassword('');
                    setConfirmPassword('');

                } else {
                    setMessage(response.data.error);
                }


            })
            .catch(error => {
                console.error('Error changing password:', error);
                setMessage('An error occurred while changing the password');

            });



    };


    const getProfile = () => {
        // axios.get(`${process.env.REACT_APP_API_URL}/user-profile/${userToken}`, {
        axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
            headers: {
                // 'Authorization': `${process.env.REACT_APP_API_KEY}`,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                console.log(res)
                if (res.data.error) {
                    Swal.fire({
                        title: res.data.message,
                        text: '',
                        icon: 'error',
                    })
                    localStorage.clear();
                    window.location.href = "/";
                }
                setDate(res.data.data)
                localStorage.setItem('fname', res.data.data['fname']);
                localStorage.setItem('lname', res.data.data['lname']);;


            })
            .catch(error => {
                console.log(error)
            })
    }




    return (
        <>

            <section className="section profile dashboard">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <img src={profile} />
                                <div className="change-profile d-none">
                                    <i className="bi bi-pencil-square" data-bs-toggle="modal" data-bs-target="#model-profile"></i>
                                </div>
                                <h2>{fname + ' ' + lname}  </h2>
                                <h3></h3>
                            </div>
                        </div>
                    </div>



                    <div className="col-xl-12 d-none-">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">
                                    <i className="bi bi-gear-fill"></i>
                                </h5>
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Change password
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <div className="  pt-4 d-flex flex-column ">
                                                    <div className="row mb-3">
                                                        {message &&
                                                            <>
                                                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                                    {message}
                                                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <form onSubmit={handleSubmitChangePassword}>
                                                        <div className="row mb-3">
                                                            <label className="col-md-4 col-lg-3 col-form-label">New Password</label>
                                                            <div className="  col-lg-9">
                                                                <input
                                                                    value={newPassword}
                                                                    onChange={handleChange}
                                                                    name="newPassword"
                                                                    type="password"
                                                                    required
                                                                    className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label className="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
                                                            <div className="col-md-8 col-lg-9">
                                                                <input
                                                                    value={confirmPassword}
                                                                    onChange={handleChange}
                                                                    name="confirmPassword"
                                                                    type="password"
                                                                    required
                                                                    className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="text-center">
                                                            <button type="submit" className="btn btn-primary">Change Password</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );


}

export default Profile;