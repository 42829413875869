import React from "react";


import './LoadingOverlayStyle.css';

const LoadingOverLay = () => {
    return (
        <>

            <div className="loading-overlay">
                <div className="loader"></div>
            </div>

        </>
    );

}

export default LoadingOverLay;