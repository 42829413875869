import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import validator from "validator";

import axiosInstance from "../../axiosInstance";

const ValueChain_salesProcess = ({ cust_id, customer, onUpdated }) => {
  const [formErrors, setFormErrors] = useState([]);

  // const [selectedStep, setSelectedStep] = useState('');
  const [cust_salesProcess, setCust_salesProcess] = useState("");
  // const [cust_salesProcessRemark, setCust_salesProcessRemark] = useState('');
  const [showType, setShowType] = useState();
  // const [textType, setTextType] = useState();
  const [data, setData] = useState([]);

  const [cust_HF, setCust_HF] = useState(customer["cust_HF"]); ///Farming or Hunting
  const [cust_HFStatus, setCust_HFStatus] = useState(); ///Farming or Hunting ---> status
  const [cust_HFSystem, setCust_HFSystem] = useState(); ///Farming  ---> status
  const [cust_segment, setCust_segment] = useState(); ///Farming  ---> Customer Segment
  const [cust_salesProcessRemark, setCust_salesProcessRemark] = useState();
  const [cust_salesProcessWonLost1, setCust_salesProcessWonLost1] = useState();
  const [
    cust_salesProcessCloseFollowNextDate,
    setCust_salesProcessCloseFollowNextDate,
  ] = useState();

  const [salesProcess, setSalesProcess] = useState([]);
  const [salesProcessDT, setSalesProcessDT] = useState([]);
  const [paintSystemData, setPaintSystemData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [farmingData, setFarmingData] = useState([]);

  const steps = [
    {
      id: 1,
      name: "Identify Opportunities",
      description: "Identify Opportunities  หาโอกาสในการเปิดการขาย",
    },
    {
      id: 2,
      name: "Create Needs",
      description: "Create Needs หาความต้องการที่แท้จริงของลูกค้า",
    },
    {
      id: 3,
      name: "Set up Quotation & Contract",
      description:
        "Set up Quotation & Contract จัดทำใบเสนอราคา และ สัญญาจะซื้อจะขาย",
    },
    { id: 4, name: "Negotiate", description: "Negotiate เจรจาต่อรอง" },
    {
      id: 5,
      name: "Closed Won & Follow up",
      description: "Closed Won & Follow up ปิดการขาย และ ติดตาม",
    },
    { id: 6, name: "Closed Loss", description: "Closed Loss ปิดการขายไม่ได้" },
  ];

  const optionsFarmingStatus = [
    { id: 1, name: "ปกติ", description: "ปกติ" },
    { id: 2, name: "ลูกค้ามีความเสี่ยง", description: "ลูกค้ามีความเสี่ยง" },
    { id: 3, name: "ลูกค้าหลุด", description: "ลูกค้าหลุด" },
  ];

  const optionsPaintSystem = [
    { id: 1, name: "GL Full", description: "GL Full" },
    { id: 2, name: "BRC Full", description: "BRC Full" },
    {
      id: 3,
      name: "Mix with competitor brand",
      description: "Mix with competitor brand",
    },
  ];

  const optionsCustomerSegment = [
    { id: 1, name: "Relationship", description: "Relationship" },
    { id: 2, name: "Glasurit Brand", description: "Glasurit Brand" },
    { id: 3, name: "Aftersales Service", description: "Aftersales Service" },
    { id: 4, name: "Business Solution", description: "Business Solution" },
    { id: 5, name: "Price & Benefit", description: "Price & Benefit" },
    { id: 6, name: "Price & Benefit", description: "Price & Benefit" },
  ];

  const optionsWonLost = [
    {
      id: 1,
      type: "won",
      name: "Relationship (ความสัมพันธ์)",
      description: "Relationship (ความสัมพันธ์)",
    },
    {
      id: 2,
      type: "won",
      name: "Product Brand (แบรนด์สินค้า)",
      description: "Product Brand (แบรนด์สินค้า)",
    },
    {
      id: 3,
      type: "won",
      name: "Aftersales Service (บริหารหลังการขาย)",
      description: "Aftersales Service (บริหารหลังการขาย)",
    },
    {
      id: 4,
      type: "won",
      name: "Business Solution (การพัฒนาธุรกิจ)",
      description: "Business Solution (การพัฒนาธุรกิจ)",
    },
    {
      id: 5,
      type: "won",
      name: "Price & Benefit (ราคาและส่วนลด)",
      description: "Price & Benefit (ราคาและส่วนลด)",
    },
  ];

  const optionsWonLost_v0 = [
    {
      id: 1,
      type: "won",
      name: "Total Benefit & Discount (ราคา และ ผลประโยชน์)",
      description: "Total Benefit & Discount (ราคา และ ผลประโยชน์)",
    },
    {
      id: 2,
      type: "won",
      name: "Relationship (ความสัมพันธ์)",
      description: "Relationship (ความสัมพันธ์)",
    },
    {
      id: 3,
      type: "won",
      name: "Product Brand (แบรนด์สินค้า)",
      description: "Product Brand (แบรนด์สินค้า)",
    },
    {
      id: 4,
      type: "won",
      name: "Aftersales Services (บริการหลังการขาย)",
      description: "Aftersales Services (บริการหลังการขาย)",
    },
    { id: 5, type: "won", name: "VAS", description: "VAS" },
    {
      id: 6,
      type: "won",
      name: "Product Quality & Performances (คุณภาพสินค้า)",
      description: "Product Quality & Performances (คุณภาพสินค้า)",
    },
    {
      id: 7,
      type: "won",
      name: "Delivery (การจัดส่งสินค้า)",
      description: "Delivery (การจัดส่งสินค้า)",
    },
    {
      id: 8,
      type: "won",
      name: "Paint Dealer (ร้านค้าตัวแทนจำหน่าย)",
      description: "Paint Dealer (ร้านค้าตัวแทนจำหน่าย)",
    },
    {
      id: 9,
      type: "won",
      name: "Brand Approval",
      description: " Brand Approval",
    },
    {
      id: 10,
      type: "won",
      name: "Color Solutions (สูตรสี และ เครื่องมือค้นหาสูตรสี)",
      description: "Color Solutions (สูตรสี และ เครื่องมือค้นหาสูตรสี)",
    },

    {
      id: 11,
      type: "lost",
      name: "Total Benefit & Discount (ราคา และ ผลประโยชน์)",
      description: "Total Benefit & Discount (ราคา และ ผลประโยชน์)",
    },
    {
      id: 12,
      type: "lost",
      name: "Relationship (ความสัมพันธ์)",
      description: "Relationship (ความสัมพันธ์)",
    },
    {
      id: 13,
      type: "lost",
      name: "Product Brand (แบรนด์สินค้า)",
      description: "Product Brand (แบรนด์สินค้า)",
    },
    {
      id: 14,
      type: "lost",
      name: "Aftersales Services (บริการหลังการขาย)",
      description: "Aftersales Services (บริการหลังการขาย)",
    },
    { id: 15, type: "lost", name: "VAS", description: "VAS" },
    {
      id: 16,
      type: "lost",
      name: "Product Quality & Performances (คุณภาพสินค้า)",
      description: "Product Quality & Performances (คุณภาพสินค้า)",
    },
    {
      id: 17,
      type: "lost",
      name: "Delivery (การจัดส่งสินค้า)",
      description: "Delivery (การจัดส่งสินค้า)",
    },
    {
      id: 18,
      type: "lost",
      name: "Paint Dealer (ร้านค้าตัวแทนจำหน่าย)",
      description: "Paint Dealer (ร้านค้าตัวแทนจำหน่าย)",
    },
    {
      id: 19,
      type: "lost",
      name: "Brand Approval",
      description: "Brand Approval",
    },
    {
      id: 20,
      type: "lost",
      name: "Color Solutions (สูตรสี และ เครื่องมือค้นหาสูตรสี)",
      description: "Color Solutions (สูตรสี และ เครื่องมือค้นหาสูตรสี)",
    },
  ];

  const getMasterData = async () => {
    try {
      const [
        salesProcess,
        salesProcessDT,
        paintSystemResponse,
        segmentResponse,
        farmingResponse,
      ] = await Promise.all([
        axiosInstance.get("/setup-dbsalesprocess"),
        axiosInstance.get("/setup-dbsalesprocessDT"),
        axiosInstance.get("/setup-dbpaintsystem"),
        axiosInstance.get("/setup-dbsegment"),
        axiosInstance.get("/setup-dbfarming"),
      ]);

      // console.log(paintSystemResponse.data);
      // console.log(segmentResponse.data);
      // console.log(farmingResponse.data);
      setSalesProcessDT(salesProcessDT.data);
      setSalesProcess(salesProcess.data);
      setPaintSystemData(paintSystemResponse.data);
      setSegmentData(segmentResponse.data);
      setFarmingData(farmingResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      //   setLoading(false);
    }
  };

  const handleOptionChange = (event) => {
    setCust_salesProcess(event.target.value);
    // console.log(event.target.value);
    setShowType("");

    if (event.target.value === "5") {
      // console.log('won')
      setShowType("won");
    }

    if (event.target.value === "6") {
      // console.log('lost')
      setShowType("lost");
    }
  };

  useEffect(() => {
    getData();
    getMasterData();
  }, []);

  const getData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/value-chain-getSaleProcess/${cust_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      cust_salesProcess: cust_salesProcess,
      cust_salesProcessRemark: cust_salesProcessRemark,
    };

    if (showType) {
      // Check if
      const isValid = updateData(); // Call updateData and store validation result
      if (!isValid) return; // If validation fails, stop further execution
    }

    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/value-chain-updateSalesProcess/${cust_id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Data Updated Successfully",
          text: "Your data has been updated.",
        });
        getData();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong. Please try again later.",
        });
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const updateData = () => {
    const errors = {};

    // Common validation checks
    if (!cust_salesProcessWonLost1)
      errors.cust_salesProcessWonLost1 = "เหตุผล is required";
    if (!cust_salesProcessCloseFollowNextDate)
      errors.cust_salesProcessCloseFollowNextDate = "วันที่ is required";

    // Additional validation checks based on showType
    if (showType === "won") {
      if (!cust_HFStatus) errors.cust_HFStatus = "Farming Status is required";
      if (!cust_HFSystem) errors.cust_HFSystem = "Paint System is required";
      if (!cust_segment) errors.cust_segment = "Customer Segment is required";
    }

    console.log(errors);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const data = {
        cust_HFStatus: showType === "lost" ? null : cust_HFStatus,
        cust_HFSystem: showType === "lost" ? null : cust_HFSystem,
        cust_segment: showType === "lost" ? null : cust_segment,
        cust_salesProcessWonLost1: cust_salesProcessWonLost1,
        cust_salesProcessCloseFollowNextDate:
          cust_salesProcessCloseFollowNextDate,
        // cust_salesProcessWonLost1: showType === 'won' ? null : cust_salesProcessWonLost1,
        // cust_salesProcessCloseFollowNextDate: showType === 'won' ? null : cust_salesProcessCloseFollowNextDate
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/value-chain-update/${cust_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          console.log("updateData has been updated");
        })
        .catch((error) => {
          console.log(error);
        });
      return true; // Return true indicating successful submission
    } else {
      return false; // Return false indicating validation failure
    }
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6 ">
          <form className="row " onSubmit={handleSubmit}>
            <div class="row mb-3">
              <label htmlFor="inputText" class="col-sm-4 col-form-label">
                Step
              </label>
              <div class="col-sm-8">
                <select
                  value={cust_salesProcess}
                  onChange={handleOptionChange}
                  className="form-control"
                  required
                >
                  <option value="">- Choose -</option>
                  {/* {steps.map(option => ( */}
                  {/* {steps.filter(option => option.name !== 'Identify Opportunities').map(option => ( */}
                  {/* {steps.filter(option => !data.includes(option.name)).map(option => ( */}

                  {/* {steps
                    .filter(
                      (option) =>
                        !data.some((dataItem) => dataItem.pID === option.id)
                    )
                    .map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.id}. {option.name} {option.description}
                      </option>
                    ))} */}
                  {/* {steps.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.id}. {option.name} {option.description}
                    </option>
                  ))} */}

                  {salesProcess.map((option) => (
                    <option key={option.pID} value={option.pID}>
                      {option.pName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <label htmlFor="inputText" class="col-sm-4 col-form-label">
                Remark
              </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  value={cust_salesProcessRemark}
                  onChange={(e) => setCust_salesProcessRemark(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="pb-1">
              <hr />

              {showType && (
                <>
                  <div class="row mb-3">
                    <label htmlFor="inputText" class="col-sm-4 col-form-label">
                      เหตุผลที่
                      {showType === "won" ? "ปิดการขายได้" : "ปิดการขายไม่ได้"}
                    </label>
                    <div class="col-sm-8">
                      <select
                        value={cust_salesProcessWonLost1}
                        onChange={(e) =>
                          setCust_salesProcessWonLost1(e.target.value)
                        }
                        className={`form-control ${
                          formErrors.cust_salesProcessWonLost1 && `is-invalid `
                        }`}
                      >
                        <option value="">- Choose -</option>
                        {/* {optionsWonLost.map(option => ( */}
                        {/* {optionsWonLost
                          .filter((option) => option.type !== showType)
                          .map((option) => (
                            <option key={option.id} value={option.name}>
                              {option.name}
                            </option>
                          ))} */}
                        {/* {optionsWonLost.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))} */}

                        {salesProcessDT.map((option) => (
                          <option key={option.pSubID} value={option.pSubName}>
                            {option.pSubName}
                          </option>
                        ))}
                      </select>
                      {formErrors.cust_salesProcessWonLost1 && (
                        <div className="form-control-feedback">
                          {formErrors.cust_salesProcessWonLost1}
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label htmlFor="inputText" class="col-sm-4 col-form-label">
                      {" "}
                      {showType === "won"
                        ? "วันที่ปิดการขาย"
                        : "วันที่ติดตามครั้งถัดไป"}{" "}
                    </label>
                    <div class="col-sm-8">
                      <input
                        value={cust_salesProcessCloseFollowNextDate}
                        onChange={(e) =>
                          setCust_salesProcessCloseFollowNextDate(
                            e.target.value
                          )
                        }
                        className={`form-control ${
                          formErrors.cust_salesProcessCloseFollowNextDate &&
                          `is-invalid `
                        }`}
                        type="date"
                      />
                      {formErrors.cust_salesProcessCloseFollowNextDate && (
                        <div className="form-control-feedback">
                          {formErrors.cust_salesProcessCloseFollowNextDate}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                </>
              )}

              {showType === "won" && (
                <>
                  <div class="row mb-3">
                    <label htmlFor="inputText" class="col-sm-4 col-form-label">
                      Farming Status
                    </label>

                    <div class="col-sm-8">
                      <select
                        value={cust_HFStatus}
                        onChange={(e) => setCust_HFStatus(e.target.value)}
                        className={`form-control ${
                          formErrors.cust_HFStatus && `is-invalid `
                        }`}
                      >
                        <option value="">- Choose -</option>
                        {farmingData.map((farming, index) => (
                          <option key={index} value={farming.farmingID}>
                            {farming.farmingName}
                          </option>
                        ))}

                        {/* {optionsFarmingStatus.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))} */}
                      </select>
                      {formErrors.cust_HFStatus && (
                        <div className="form-control-feedback">
                          {formErrors.cust_HFStatus}
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label htmlFor="inputText" class="col-sm-4 col-form-label">
                      Paint System
                    </label>
                    <div class="col-sm-8">
                      <select
                        value={cust_HFSystem}
                        onChange={(e) => setCust_HFSystem(e.target.value)}
                        className={`form-control ${
                          formErrors.cust_HFSystem && `is-invalid `
                        }`}
                      >
                        <option value="">- Choose -</option>
                        {paintSystemData.map((paintSystem, index) => (
                          <option key={index} value={paintSystem.paintID}>
                            {paintSystem.paintName}
                          </option>
                        ))}

                        {/* {optionsPaintSystem.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))} */}
                      </select>
                      {formErrors.cust_HFSystem && (
                        <div className="form-control-feedback">
                          {formErrors.cust_HFSystem}
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label htmlFor="inputText" class="col-sm-4 col-form-label">
                      Customer Segment
                    </label>
                    <div class="col-sm-8">
                      <select
                        value={cust_segment}
                        onChange={(e) => setCust_segment(e.target.value)}
                        className={`form-control ${
                          formErrors.cust_segment && `is-invalid `
                        }`}
                      >
                        <option value="">- Choose -</option>
                        {segmentData.map((segment, index) => (
                          <option key={index} value={segment.segmentID}>
                            {segment.segmentName}
                          </option>
                        ))}

                        {/* {optionsCustomerSegment.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))} */}
                      </select>
                      {formErrors.cust_segment && (
                        <div className="form-control-feedback">
                          {formErrors.cust_segment}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="col-md-6">
          <div class="activity">
            {data ? (
              <>
                {data.map((item, key) => (
                  <div class="activity-item d-flex" key={key}>
                    <div class="activite-label">
                      {new Date(item.logCreateDate)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })
                        .replace(",", " /")}
                    </div>
                    <i class="bi bi-circle-fill activity-badge text-success align-self-start"></i>
                    <div class="activity-content">
                      {item.pName}
                      <div class="fw-bold text-dark">
                        {" "}
                        <em>Remark: </em> {item.logRemark}
                      </div>
                      {item.pID == 5 && (
                        <>
                          <div class="fw-bold1 text-dark">
                            <strong>Farming Status: </strong> {item.farmingName}
                          </div>
                          <div class="fw-bold1 text-dark">
                            <strong>Paint System: </strong> {item.paintName}
                          </div>
                          <div class="fw-bold1 text-dark">
                            <strong>Customer Segment: </strong>{" "}
                            {item.segmentName}
                          </div>
                        </>
                      )}

                      {item.pID === 5 ||
                        (item.pID === 6 && (
                          <>
                            <div class="fw-bold1 text-dark">
                              <strong>
                                {item.pID === 5
                                  ? "เหตุผลที่ Won"
                                  : "เหตุผลที่ Lost"}
                                :{" "}
                              </strong>{" "}
                              {item.cust_salesProcessWonLost1}
                            </div>
                            <div class="fw-bold1 text-dark">
                              <strong>
                                {item.pID === 5
                                  ? "วันที่ปิดการขาย"
                                  : "วันที่ติดตามครั้งถัดไป"}
                                :{" "}
                              </strong>{" "}
                              {item.cust_salesProcessCloseFollowNextDate}
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>data not found</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ValueChain_salesProcess;
