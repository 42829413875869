import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

import LoadingSkeleton from "../loading/LoadingSkeleton";
import DataTable from "react-data-table-component";

const NoneMSOvalueChain = () => {
  // const history = useHistory();
  const navigate = useNavigate();

  const [valueChain, setValueChain] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/value-chain/nonemso`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res)
        setValueChain(res.data.data);
        setFilteredData(res.data.data); //
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        Swal.fire({
          title: error.message,
          text: "Please try again later",
          icon: "error",
          // confirmButtonText: 'Cool'
        });
      });
  }, []);

  const confirmNavigation = (cust_id, CustShortName) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to view details for ${CustShortName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/value-chain-detail/${cust_id}`);
      }
    });
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "5%",
    },

    {
      name: "Cust Code",
      selector: (row) => row.CustCode,
      width: "15%",
      sortable: true,
    },
    {
      name: "Cus Name [Short Name]",
      selector: (row) => row.CustShortName,
      cell: (row) => (
        <span
          style={{
            cursor: "pointer",
            color: "blue",
           }}
          onClick={() => confirmNavigation(row.cust_id, row.CustShortName)}
        >
          {row.CustShortName}
        </span>
      ),
      width: "40%",
      sortable: true,
    },
    {
      name: "Sales",
      selector: (row) => row.Sales,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.cust_status,
      cell: (row) => (row.cust_status === "A" ? "Active" : "InActive"),
    },

    {
      name: "Create Date",
      selector: (row) => row.cust_createDate,
      sortable: true,
    },
  ];

  //   const handleSearchChange = (e) => {
  //     const searchQuery = e.target.value;
  //     setQuery(searchQuery);
  //     const filteredResults = valueChain.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     );

  //     setFilteredData(filteredResults);
  //   };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setQuery(query);
    const filtered = valueChain.filter((item) => {
      return (
        item.CustCode.toLowerCase().includes(query.toLowerCase()) ||
        item.CustShortName.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  if (isLoading) {
    return (
      <>
        <LoadingSkeleton />
      </>
    );
  }

  return (
    <section className="dashboard">
      <div className="pagetitle">
        <h1>Non - MSO Customer</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Value Chain</a>
            </li>
            {/* <li className="breadcrumb-item">Forms</li> */}
            <li className="breadcrumb-item active">MSO Group</li>
          </ol>
        </nav>
      </div>

      <div className="col-12">
        <div className="card info-card sales-card">
          <div className="row pt-3 p-3 ">
            <div className="col-md-9"> </div>
            <div className="col-md-2 text-end ">
              <input
                type="text"
                className="form-control"
                placeholder="Search...."
                value={query}
                onChange={handleSearchChange}
              />
            </div>
            <div className="col-md-1 text-end ">
              <div className="text-end cursor-pointer ">
                <Link to="/value-chain-create-none-mso">
                  <div className="icon">
                    <i
                      className="bx bxs-folder-plus"
                      style={{ fontSize: "40px" }}
                    ></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="card-body">
            <h3 className="card-title">Customer Data </h3>

            <DataTable
              columns={columns}
              //   data={valueChain}
              data={filteredData}
              // data={mso}
              pagination
              fixedHeader
              // progressPending={pending}
              // progressComponent={<Loading />}
              sortField="DueDate"
              sortDirection="desc"
            />

            <hr />
            <div className="table-responsive d-none">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Cust Code</th>
                    <th scope="col">Cus Name [Short Name]</th>
                    <th>Status</th>
                    <th scope="col">Create Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, key) => (
                    <tr key={key}>
                      <th scope="row">{key + 1}</th>

                      <td>{item["CustCode"]}</td>
                      <td>
                        <Link
                          key={key}
                          to={{
                            pathname: `/value-chain-detail/${item["cust_id"]}`,
                            state: `${item["cust_id"]}`,
                          }}
                        >
                          {item["CustShortName"]}
                        </Link>
                      </td>
                      <td>
                        {item["cust_status"] === "A" ? "Active" : "InActive"}
                      </td>
                      <td>{item["cust_createDate"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoneMSOvalueChain;
