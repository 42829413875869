import React, { useState } from "react";
import axios from "axios";

// import API from '../../config.json'
import Swal from "sweetalert2";
// import Logo from "../../logo.png"
// import Logo from "../../assets/picture/brc_logo.png"

// import Logo from "../../assets/img/logo.png"
// import EasyEReceipt from "../../assets/img/EasyE-Receipt.png"
import Logo from "../../assets/picture/brc_logo.png";

// import './Style.css'
import { Link } from "react-router-dom";
// import LoadingOverLay from "../loading/LoadingOverLay";
// import { isLogin } from '../../auth';

// import CompanyLogoRow from "../companies/CompanyLogoRow";
import LoadingOverLay from "../loading/LoadingOverLay";

const Forgotpassword = () => {
  const [formErrors, setFormErrors] = useState([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!email || email.length <= 3) {
      errors.email = "asdasd";
    }
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const data = {
        username: email,
      };
      // axios.defaults.timeout = 5000; // Set default timeout to 5 seconds
      await axios
        .post(`${process.env.REACT_APP_API_URL_TOKEN}/forgotpassword`, data, {
          headers: {
            Authorization: `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          // console.log(response)
          console.log(response.data);

          if (response.data.userCheck) {
            Swal.fire({
              title: response.data["message"],
              text: "",
              icon: "success",
            });
            setEmail("");
            window.location.href = "/";
          } else {
            Swal.fire({
              title: response.data["message"],
              text: "",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          // console.log(error.response)
          Swal.fire({
            title: error.message,
            text: "Please try again later ",
            icon: "info",
            confirmButtonText: "OK",
          });
        });
    }
  };

  return (
    <>
      {isLoading && <LoadingOverLay />}

      <section className="loginDiv section   min-vh-100 d-flex flex-column align-items-center justify-content-center  ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center py-4">
                <div className="logo-login d-flex align-items-center w-auto">
                  <img src={Logo} />
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">
                      <h2 className="text-center  ">NND</h2>
                      Login to Your Account
                    </h5>
                    <p className="text-center small">
                      Enter your username to reset password
                    </p>
                    <div className="text-right g-pa-5"></div>
                  </div>
                  {error ? (
                    <div
                      className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show"
                      role="alert"
                    >
                      {error}
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <form className="row g-3 needs-validation">
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className={`form-control ${
                            formErrors.email && `is-invalid `
                          }`}
                          id="floatingInput"
                          placeholder="name@example.com"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        {formErrors.email && (
                          <div className="text-danger">
                            Please enter your email address{" "}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100"
                        onClick={handleForgotPassword}
                      >
                        Reset password
                      </button>
                    </div>
                  </form>

                  <p className="small mb-0 text-center">
                    <Link to="/">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="credits d-none">
          Copyright © KRATOS INNO TECH CO., LTD. @2023
        </div>
      </section>
    </>
  );
};

export default Forgotpassword;
