import React, { useState, useEffect } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { logout, login, isLogin, getToken } from '../../auth';


const ValueChain_painPointValueProposition = ({ cust_id }) => {

    const [data, setData] = useState([])
    const [dataDB, setDataDB] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);

    const [isHide, setIsHide] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);


    useEffect(() => {
        if (cust_id) {
            getPainPoint()
        }
    }, [])



    const getPainPointDB = async (dataPaintPoint) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/value-chain-painpointDB`,
            {
                headers: {
                     'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                const filteredArray1 = res.data.data.filter((item1) => {
                    return !dataPaintPoint.some((item2) => item1.ppt_id === item2.ppt_id);
                });
                setDataDB(filteredArray1)
            })
            .catch(error => {
                console.log(error)
            })
    }


    const getPainPoint = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/value-chain-painpoint/${cust_id}`,
            {
                headers: {
                     'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                setData(res.data.data)
                // console.log(res.data.data.length)
                if (res.data.data.length >= 3) {
                    setIsHide(true)
                    setShow(false)
                } else {
                    setIsHide(false)
                }
                getPainPointDB(res.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }



    const handleCheckboxChange = async (itemId, subjectName, type) => {

        const result = await Swal.fire({
            title: 'Confirm Action',
            text: `Do you want to  ${type === 'C' ? 'add' : 'delete'} :  ${subjectName}  this item?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: type === 'C' ? 'Add' : 'Delete',
            cancelButtonText: 'Cancel',
        });

        if (result.isConfirmed) {
            const value = itemId;
            // console.log(value)
            painpointCreate(value, type)
        }

    };


    const painpointCreate = (itemId, type) => {
        const data = {
            'cust_id': cust_id,
            'ppt_id': itemId,
            'action': type,
        }
        axios.post(`${process.env.REACT_APP_API_URL}/value-chain-painpointCreate`, data,
            {
                headers: {
                     'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.messages['success'],
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
                getPainPoint()

            })
            .catch(error => {
                console.log(error)
                Swal.fire({
                    title: error.message,
                    text: 'Please try again later',
                    icon: 'error',
                })
            })
    }


    return (

        <div className="row">
    



            <div className="col-12">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th></th>
                            <th scope="col">Pain Point <br/> (สาเหตุต้นตอ)</th>
                            <th scope="col" colSpan={2}>Value Proposition <br/> (ข้อเสนอที่มีคุณค่าทางแก้ปัญหา)	</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data ? (
                            <>

                                {data.map((item, key) => (
                                    <tr key={key}>
                                        <td scope="row">{key + 1}</td>
                                        <td>
                                            <i
                                                className="ri-delete-bin-6-fill text-danger g-text-cursor"
                                                onClick={() => handleCheckboxChange(item['ppt_id'], item['ppt_subjecy'], 'D')}
                                            ></i>
                                        </td>
                                        <td>{item['ppt_subjecy']} </td>
                                        <td>{item['ppt_value']}</td>
                                        <td>{item['ppt_proposition']} </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <td className="text-center" colSpan={4} >data not found</td>
                        )}

                    </tbody>
                </table>

                <hr />
                {!isHide &&

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th></th>
                                <th scope="col">Pain Point  (สาเหตุต้นตอ)</th>
                                <th scope="col" colSpan={2}>Value Proposition (ข้อเสนอที่มีคุณค่าทางแก้ปัญหา)	</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data ? (
                                <>
                                    {dataDB.map((item, key) => (
                                        <tr key={key}>
                                            <th scope="row">{key + 1}</th>
                                            <th>
                                                <i
                                                    className="ri-add-circle-fill g-text-cursor"
                                                    onClick={() => handleCheckboxChange(item['ppt_id'], item['ppt_subjecy'], 'C')}
                                                ></i>

                                            </th>
                                            <td>{item['ppt_subjecy']} </td>
                                            <td>{item['ppt_value']}</td>
                                            <td>{item['ppt_proposition']} </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <td className="text-center" colSpan={4} >data not found</td>
                            )}

                        </tbody>
                    </table>
                }

            </div>

        </div>
    );
}

export default ValueChain_painPointValueProposition;