import React, { useState, useEffect } from "react";
import axios from "axios";
// import API from '../../config.json'
import Swal from "sweetalert2";
// import Logo from "../../logo.png"
import Logo from "../../assets/picture/brc_logo.png";
import "./Style.css";
import { Link } from "react-router-dom";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      window.location.href = "/profile";
    }
  }, []);

  // if (isLoggedIn) {
  //     return <div> <Profile /></div>;
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      username: username,
      password: password,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL_TOKEN}/login`, data, {
        // axios.post(`http://api-brc.localhost/token/login`, data, {
        headers: {
          Authorization: `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.logined) {
          const token = response.data.token;
          console.log(token);
          // set JWT token to local
          localStorage.setItem("token", token);
          localStorage.setItem("usertoken", token);
          //set token to axios common header
          // setAuthToken(token);
          Swal.fire({
            title: response.data["message"],
            text: " ",
            icon: "success",
          });
          window.location.href = "/profile";
        } else {
          Swal.fire({
            title: response.data["message"],
            text: "Please try again ",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);

        // Swal.fire({
        //     title: error.message,
        //     text: 'Please try again later ',
        //     icon: 'info',
        //     confirmButtonText: 'OK'
        // })
      });
  };

  return (
    <section className="loginDiv section   min-vh-100 d-flex flex-column align-items-center justify-content-center  ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex justify-content-center py-4">
              <div className="logo-login d-flex align-items-center w-auto">
                <img src={Logo} />
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">
                    <h2 className="text-center  ">NND</h2>
                    Login to Your Account
                  </h5>
                  <p className="text-center small">
                    Enter your username &amp; password to login
                  </p>
                  <div className="text-right g-pa-5"></div>
                </div>
                {error ? (
                  <div
                    className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show"
                    role="alert"
                  >
                    {error}
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                ) : (
                  <div></div>
                )}

                <form
                  className="row g-3 needs-validation"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                      <label>Email address</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="password"
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <label>Password</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">
                      Login
                    </button>
                  </div>
                  <div className="col-12">
                    <p className="small mb-0">
                      {" "}
                      <Link to="/forgotpassword">Forgot password ? </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
