import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {

        Swal.fire({
            title: 'Ate you sure you want to sign out?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Logout',
        }).then((result) => {
            if (result.isConfirmed) {

                localStorage.removeItem('token')
                localStorage.clear();
                Swal.fire('logout successful !', '', 'success')
                navigate("/");
                navigate(0)
            }

        })

    }, [])

    return (
        <></>
    );
}

export default Logout;