import React from "react";


import './LoadingSkeletonStyle.css';

const LoadingSkeleton = () => {
    return (
        <>
            <section className="dashboard">

                <div className="card">
                    <div className="card-body">
                        <div className="pt-3">
                            <div className="skeleton-item"> </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="pt-3">
                            <div className="skeleton-item"> </div>
                            <div className="skeleton-item"> </div>
                            <div className="skeleton-item"> </div>
                            <div className="skeleton-item"> </div>
                            <div className="skeleton-item"> </div>
                            <div className="skeleton-item"> </div>
                            <div className="skeleton-item"> </div>
                            <div className="skeleton-item"> </div>

                        </div>
                    </div>
                </div>
            </section>
        </>




    );

}

export default LoadingSkeleton;