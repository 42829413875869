import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import axiosInstance from "../../axiosInstance";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import validator from "validator";
import { logout, login, isLogin, getToken } from "../../auth";
import "./Style.css";

const ValueChain_contactRole = ({ cust_id }) => {
  const [data, setData] = useState([]);

  const [con_name, setCon_name] = useState("");
  const [con_title, setCon_title] = useState("");
  const [con_phoneNo, setCon_phoneNo] = useState("");
  const [con_role, setCon_role] = useState([]);
  const [con_relationship, setCon_relationship] = useState("");
  const [con_status, setCon_status] = useState("I");
  const [con_id, setCon_id] = useState();

  const [modelTitle, setModelTitle] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formErrors, setFormErrors] = useState([]);

  const [optionsRole, setOptionsRole] = useState([]);
  const [optionsRelationship, setOptionsRelationship] = useState([]);

  // const [optionsRole, setOptionsRole] = useState([
  //     { value: 'Decision Maker', label: 'Decision Maker' },
  //     { value: 'Influencer', label: 'Influencer' },
  //     { value: 'Contact Person', label: 'Contact Person' },

  // ]);

  // const optionsRole = [
  //     { value: 'Decision Maker', label: 'Decision Maker' },
  //     { value: 'Influencer', label: 'Influencer' },
  // ]

  // const optionsRelationship = [
  //     { value: 'Supporter', label: 'Supporter' },
  //     { value: 'Neutral', label: 'Neutral' },
  //     { value: 'Non-supports', label: 'Non-supports' },
  //     { value: 'Coach', label: 'Coach' },
  // ];

  useEffect(() => {
    getMasterData();

    if (cust_id) {
      getContactRole();
    }
  }, []);

  const getMasterData = async () => {
    try {
      const [optionsRole, optionsRelationship] = await Promise.all([
        axiosInstance.get("/setup-dbPowerMapRole"),
        axiosInstance.get("/setup-dbPowerMapRelationship"),
      ]);

      setOptionsRole(optionsRole.data);
      setOptionsRelationship(optionsRelationship.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      //   setLoading(false);
    }
  };

  const getContactRole = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/value-chain-detailContactRole/${cust_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);

        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleSelectChange = (selectedOption) => {
  //     setCon_role(selectedOption);
  //     // onChangeType(selectedOption)
  //     console.log(selectedOption)
  // };

  const model = (e) => {
    if (e === null) {
      if (data.some((item) => item.con_role === "Decision Maker")) {
        const filteredOptionType = optionsRole.filter((item1) => {
          return item1.value != "Decision Maker";
        });
        setOptionsRole(filteredOptionType);
      }

      setShow(true);
      setCon_id(null);
      setCon_name("");
      setCon_phoneNo("");
      setCon_title("");
      setCon_relationship("");
      setCon_role("");
      setCon_relationship("");
      setCon_status("I");
      // setModelTitle('Create New MSO')
    } else {
      setShow(true);
      setCon_id(e);
      // setModelTitle('MSO Detail')
      console.log("edit from");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(con_role);
    console.log(con_role["value"]);

    const errors = {};
    if (!validator.isLength(con_name, { min: 1 })) {
      errors.con_name = "Name is required";
    }
    if (!validator.isLength(con_title, { min: 1 })) {
      errors.con_title = "Title (ตำแหน่ง) is required";
    }
    if (!validator.isLength(con_phoneNo, { min: 1, max: 100 })) {
      errors.con_phoneNo = "Phone number is required  ";
    }

    if (con_role["value"] === undefined) {
      errors.con_role = "Role is required";
    } else {
      if (!validator.isLength(con_role["value"], { min: 1 })) {
        errors.con_role = "Role is required";
      }
    }

    if (con_relationship["value"] === undefined) {
      errors.con_relationship = "Relationship is required";
    } else {
      if (!validator.isLength(con_relationship["value"], { min: 1 })) {
        errors.con_relationship = "Relationship is required";
      }
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = {
        cust_id: cust_id,
        con_name: con_name,
        con_title: con_title,
        con_phoneNo: con_phoneNo,
        con_role: con_role["value"],
        con_relationship: con_relationship["value"],
        con_status: con_status,
      };
      // console.log(con_id)
      console.log(data);

      if (con_id === null) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/value-chain-detailContactRoleCreate`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);

            Swal.fire({
              title: "Success!",
              text: res.data.messages["success"],
              icon: "success",
              confirmButtonText: "OK",
            });

            getContactRole();
            setShow(false);
          })
          .catch((error) => {
            console.log(error);
            setShow(false);
            Swal.fire({
              title: error.message,
              text: "Please try again later",
              icon: "error",
              // confirmButtonText: 'Cool'
            });
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/value-chain-detailContactRoleUpdate/${con_id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);

            Swal.fire({
              title: "Success!",
              text: res.data["message"],
              icon: "success",
              confirmButtonText: "OK",
            });

            getContactRole();
            // setShow(false)
          })
          .catch((error) => {
            console.log(error);

            Swal.fire({
              title: error.message,
              text: "Please try again later",
              icon: "error",
              // confirmButtonText: 'Cool'
            });
          });
      }
    }
  };

  return (
    <div className="row">
      <div
        className="filter text-end cursor-pointer p-2"
        onClick={(e) => model(null)}
      >
        <div className="icon">
          <i className="bx bxs-folder-plus" style={{ fontSize: "40px" }}></i>
        </div>
      </div>
      <Modal
        backdrop="static"
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modelTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="from-control ">
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">
                <div>Name</div>
                <div>ชื่อ - นามสกุล</div>
              </label>
              <div className="col-sm-8">
                <input
                  value={con_name}
                  onChange={(e) => setCon_name(e.target.value)}
                  type="text"
                  className="form-control"
                />
                {formErrors.con_name && (
                  <div className="form-control-feedback">
                    {formErrors.con_name}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">
                <div>Title</div>
                <div>ตำแหน่ง</div>
              </label>
              <div className="col-sm-8">
                <input
                  value={con_title}
                  onChange={(e) => setCon_title(e.target.value)}
                  type="text"
                  className="form-control"
                />
                {formErrors.con_title && (
                  <div className="form-control-feedback">
                    {formErrors.con_title}
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">
                <div>Phone No.</div>
                <div>เบอร์โทรศัพท์</div>
              </label>
              <div className="col-sm-8">
                <input
                  value={con_phoneNo}
                  onChange={(e) => setCon_phoneNo(e.target.value)}
                  type="number"
                  // minLength={10}
                  // maxLength={10}
                  className="form-control"
                />
                {formErrors.con_phoneNo && (
                  <div className="form-control-feedback">
                    {formErrors.con_phoneNo}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">
                <div>Role</div>
                <div>บทบาทหน้าที่</div>
              </label>
              <div className="col-sm-8">
                {/* <Select
                  value={con_role}
                  onChange={(e) => setCon_role(e)}
                  options={optionsRole}
                  placeholder=""
                  isSearchable
                /> */}

                <select
                  value={con_role}
                  onChange={(e) => setCon_role(e.target.value)}
                  className="form-control"
                  required
                >
                  <option value="">- Choose -</option>
                  {optionsRole.map((option) => (
                    <option key={option.con_id} value={option.con_Name}>
                      {option.con_Name}
                    </option>
                  ))}
                </select>

                {formErrors.con_role && (
                  <div className="form-control-feedback">
                    {formErrors.con_role}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">
                <div>Relationship</div>
                <div>ความสัมพันธ์</div>
              </label>
              <div className="col-sm-8">
                {/* <Select
                  value={con_relationship}
                  onChange={(e) => setCon_relationship(e)}
                  options={optionsRelationship}
                  placeholder=""
                  isSearchable
                /> */}

                <select
                  value={con_relationship}
                  onChange={(e) => setCon_relationship(e.target.value)}
                  className="form-control"
                  required
                >
                  <option value="">- Choose -</option>
                  {optionsRelationship.map((option) => (
                    <option key={option.rel_id} value={option.rel_Name}>
                      {option.rel_Name}
                    </option>
                  ))}
                </select>

                {formErrors.con_relationship && (
                  <div className="form-control-feedback">
                    {formErrors.con_relationship}
                  </div>
                )}
              </div>
            </div>

            {con_id && (
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  <div>Status</div>
                  <div>สถานะ</div>
                </label>
                <div className="col-sm-8">
                  <select
                    onChange={(e) => setCon_status(e.target.value)}
                    value={con_status}
                    className="form-control"
                  >
                    <option value="A" {...(con_status === "A" && "selected")}>
                      {" "}
                      Active{" "}
                    </option>
                    <option value="I" {...(con_status === "I" && "selected")}>
                      {" "}
                      Inactive{" "}
                    </option>
                  </select>
                </div>
              </div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-12">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Title</th>
              <th scope="col">Phone No.</th>
              <th scope="col">Role</th>
              <th scope="col">Relationship</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              <>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td scope="row">{key + 1}</td>
                    <td>{item["con_name"]} </td>
                    <td>{item["con_title"]} </td>
                    <td> {item["con_phoneNo"]}</td>
                    <td> {item["con_role"]}</td>
                    <td>{item["con_relationship"]}</td>
                    <td>
                      {item["con_status"] === "A" ? "Actived" : "Inactivated"}
                    </td>
                    <td>
                      <i
                        onClick={(e) =>
                          model(
                            item["con_id"],
                            setCon_id(item["con_id"]),
                            setCon_name(item["con_name"]),
                            setCon_title(item["con_title"]),
                            setCon_phoneNo(item["con_phoneNo"]),
                            // setCon_role({
                            //   label: item["con_role"],
                            //   value: item["con_role"],
                            // }),
                            setCon_role(item["con_role"]),
                            setCon_relationship(item["con_relationship"]),

                            // setCon_relationship({
                            //   label: item["con_relationship"],
                            //   value: item["con_relationship"],
                            // }),
                            setCon_status(item["con_status"])
                          )
                        }
                        className="bx bxs-edit-location"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <td className="text-center" colSpan={8}>
                data not found
              </td>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ValueChain_contactRole;
