import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import LoadingOverLay from "../loading/LoadingOverLay";

const ValueChain_insurance = ({ cust_id }) => {
  const [dataIns, setDataIns] = useState([]);
  const [updatedIndex, setUpdatedIndex] = useState(null); // Track the updated row index
  const [isLoading, setIsLoading] = useState(false);

  const getInsurance = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/value-chain-insurance/${cust_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setDataIns(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (cust_id) {
      getInsurance();
    }
  }, [cust_id]);

  const handleFocus = (event) => {
    event.target.select();
  };

  const calculateTotalPercent = (item) => {
    const total =
      parseFloat(item.ins_departmentPercent) +
      parseFloat(item.ins_garagePercent) +
      parseFloat(item.ins_cashPercent);
    return total.toFixed(2);
  };

  const handleInputChange = (e, index, field) => {
    const newDataIns = [...dataIns];
    newDataIns[index][field] = e.target.value;
    newDataIns[index].totalPercent = calculateTotalPercent(newDataIns[index]);

    if (newDataIns[index].totalPercent > 100) {
      Swal.fire("Error!", "Total percentage cannot exceed 100.", "error");
      return true;
    } else {
      setDataIns(newDataIns);
      setUpdatedIndex(index); // Set the updated index to the current row
    }
  };

  const updateDatabase = (index) => {
    setIsLoading(true);
    const updatedItem = dataIns[index];
    const data = {
      ins_departmentPercent: updatedItem.ins_departmentPercent,
      ins_garagePercent: updatedItem.ins_garagePercent,
      ins_extraPaymentPercent: updatedItem.ins_extraPaymentPercent,
      ins_lastUpdated: new Date(),
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/value-chain-insuranceUpdate/${updatedItem.ins_no}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setUpdatedIndex(null); // Reset updatedIndex after update
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire("Error!", "Failed to update data.", "error");
        console.error(error);
      });
  };

  const sumTotalPercent = () => {
    let sum = 0;
    dataIns.forEach((item) => {
      sum += parseFloat(item.totalPercent);
    });
    return parseFloat(sum).toFixed(0) + "%";

    // return parseFloat(sum / dataIns.length).toFixed(0) + "%";
  };

  return (
    <>
      {isLoading && <LoadingOverLay />}

      <div className="row">
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">รายชื่อประกันภัย</th>
                <th scope="col" className="text-right">
                  อัตราส่วนเบี้ยห้าง (%)
                </th>
                <th scope="col" className="text-right">
                  อัตราส่วนเบี้ยอู่ (%)
                </th>
                <th scope="col" className="text-right">
                  อัตราส่วนเงินสด (%)
                </th>
                <th scope="col" className="text-right">
                  จำนวนรวม (%)
                </th>
                <th scope="col">Actions</th>
                <th scope="col"> Last Update</th>
              </tr>
            </thead>
            <tbody>
              {dataIns.length > 0 ? (
                dataIns.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.ins_name}</td>
                    <td className="text-right">
                      <input
                        className="form-control"
                        type="number"
                        step="1"
                        pattern="\d*"
                        onFocus={handleFocus}
                        value={
                          item.ins_departmentPercent === ".00"
                            ? 0
                            : item.ins_departmentPercent
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, "ins_departmentPercent")
                        }
                        disabled={item.ins_id >= 11 && item.ins_type === "0"}
                      />
                    </td>
                    <td className="text-right">
                      <input
                        className="form-control"
                        type="number"
                        onFocus={handleFocus}
                        value={
                          item.ins_garagePercent === ".00"
                            ? 0
                            : item.ins_garagePercent
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, "ins_garagePercent")
                        }
                        disabled={item.ins_id >= 11 && item.ins_type === "0"}
                      />
                    </td>
                    <td className="text-right">
                      <input
                        className="form-control"
                        type="number"
                        onFocus={handleFocus}
                        value={
                          item.ins_cashPercent === ".00"
                            ? 0
                            : item.ins_cashPercent
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, "ins_cashPercent")
                        }
                        disabled={item.ins_id < 11}
                      />
                    </td>
                    <td className="text-right">
                      {parseFloat(item.totalPercent)}
                    </td>
                    <td>
                      {updatedIndex === index && (
                        <button
                          className="btn btn-primary"
                          onClick={() => updateDatabase(index)}
                        >
                          Update
                        </button>
                      )}
                    </td>
                    <td>
                      {item.ins_lastUpdated &&
                        new Date(item.ins_lastUpdated)
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })
                          .replace(",", " /")}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={7}>
                    Data not found
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan={4}></td>
                <td className="text-right">Average:</td>
                <td className="text-right">{parseFloat(sumTotalPercent())}%</td>
                <td className="text-right"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ValueChain_insurance;
