import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import LoadingSkeleton from "../loading/LoadingSkeleton";


const ValueChain = () => {
    const [valueChain, setValueChain] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [query, setQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/value-chain`, {
            headers: {
                 'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                // console.log(res)
                setValueChain(res.data.data)
                setFilteredData(res.data.data); // 
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error)
                setIsLoading(false)
                Swal.fire({
                    title: error.message,
                    text: 'Please try again later',
                    icon: 'error',
                    // confirmButtonText: 'Cool'
                })


            })

    }, [])

     const handleSearchChange = (e) => {
        const searchQuery = e.target.value;
        setQuery(searchQuery);
        const filteredResults = valueChain.filter((item) =>
            Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            )
        );

        setFilteredData(filteredResults);
    }


    if (isLoading) {
        return (
            <><LoadingSkeleton /></>
        )
    }

    return (

        <section className="dashboard">
            <div className="pagetitle">
                <h1>MSO & Customer</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Value Chain</a></li>
                        {/* <li className="breadcrumb-item">Forms</li> */}
                        <li className="breadcrumb-item active">MSO & Customer</li>
                    </ol>
                </nav>
            </div>



            <div className="col-12">

                <div className="card info-card sales-card">
                    <div className="row pt-3 p-3">
                        <div className="col-md-10"> </div>
                        <div className="col-md-2">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search...."
                                value={query}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div className="card-body">
                        <h3 className="card-title">MSO & Customer  </h3>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">MSO Code</th>
                                        <th scope="col">MSO Name</th>
                                        <th scope="col">Customer Code</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Create Date</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {filteredData.map((item, key) => (
                                        <tr key={key}>
                                            <th scope="row">{key + 1}</th>
                                            <td>{item['group_code']}</td>
                                            <td>{item['group_name']}</td>
                                            <td>{item['CustCode']}</td>
                                            <td>
                                                <Link
                                                    key={key}
                                                    to={
                                                        {
                                                            pathname: `/value-chain-detail/${item['cust_id']}`,
                                                            state: `${item['cust_id']}`
                                                        }
                                                    }>
                                                    {item['CustName']}
                                                </Link>
                                            </td>
                                            <td>{item['cust_createDate']}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default ValueChain;