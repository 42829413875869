import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { logout, login, isLogin, getToken } from '../../auth';

function EmSales({ onChangeType, value }) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [defaultValue, setDefaultValue] = useState();

    useEffect(() => {
        if (value) {
            setDefaultValue(value['value'])
           
        }
        getEMCust()

    }, [onChangeType]);

    const getEMCust = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/erp-sales`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                // console.log(res)
                const data = res.data.data.map((item) => ({
                    value: item.EmpID,
                    label: item.EmpCode + ' : ' + item.EmpName,
                }));
                setOptions(data)
            })
            .catch(error => {
                console.log(error)
            })

    }

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        // console.log(selectedOption)
        onChangeType(selectedOption)

    };


    return (
        <Select
            // value={selectedOption}
            onChange={handleSelectChange}
            options={options}
            value={options.find(option => option.value === defaultValue)}
            placeholder="Search..."
            isSearchable
        />
    );
}

export default EmSales;
