import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { Link } from "react-router-dom";
import LoadingOverLay from "../loading/LoadingOverLay";
import Swal from "sweetalert2";

const ProductMix = () => {
  const [brandTypes, setBrandTypes] = useState([]);
  const [brandTypesSelect, setBrandTypesSelect] = useState([]);

  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    brandTypeID: "",
    brandTypeName: "",
    brandTypeStatus: "",
    brandName: "",
    brandStatus: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [brandTypesResponse, brandsResponse] = await Promise.all([
          axiosInstance.get("/brandTypes"),
          axiosInstance.get("/brands"),
        ]);
        setBrandTypes(brandTypesResponse.data);
        setBrands(brandsResponse.data);
        // console.log(brandsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setForm({ ...form, [name]: value });
  };

  const handleAddBrandType = async () => {
    try {
      await axiosInstance.post("/brandTypes", {
        brandTypeName: form.brandTypeName,
      });
      setForm({ ...form, brandTypeName: "" });
      const brandTypesResponse = await axiosInstance.get("/brandTypes");
      setBrandTypes(brandTypesResponse.data);
    } catch (error) {
      console.error("Error adding brand type:", error);
    }
  };

  const handleAddBrand = async () => {
    try {
      await axiosInstance.post("/brands", {
        brandTypeID: form.brandTypeID,
        brandName: form.brandName,
      });
      setForm({ ...form, brandTypeID: "", brandName: "" });
      const brandsResponse = await axiosInstance.get("/brands");
      setBrands(brandsResponse.data);
    } catch (error) {
      console.error("Error adding brand:", error);
    }
  };

  const handleDeleteBrandType = async (id) => {
    try {
      await axiosInstance.delete(`/brandTypes/${id}`);
      const brandTypesResponse = await axiosInstance.get("/brandTypes");
      setBrandTypes(brandTypesResponse.data);
    } catch (error) {
      console.error("Error deleting brand type:", error);
    }
  };

  const handleDeleteBrand = async (id) => {
    try {
      await axiosInstance.delete(`/brands/${id}`);
      const brandsResponse = await axiosInstance.get("/brands");
      setBrands(brandsResponse.data);
    } catch (error) {
      console.error("Error deleting brand:", error);
    }
  };

  const handleToggleBrandTypeStatus = async (id, status) => {
    try {
      await axiosInstance.put(`/brandTypes/${id}`, {
        brandTypeStatus: status ? 0 : 1,
      });
      const brandTypesResponse = await axiosInstance.get("/brandTypes");
      console.log(brandTypesResponse.data);
      setBrandTypes(brandTypesResponse.data);
    } catch (error) {
      console.error("Error updating brand type status:", error);
    }
  };

  const handleToggleBrandStatus = async (id, status) => {
    try {
      await axiosInstance.put(`/brands/${id}`, {
        brandStatus: status ? 0 : 1,
      });
      const brandsResponse = await axiosInstance.get("/brands");
      setBrands(brandsResponse.data);
    } catch (error) {
      console.error("Error updating brand status:", error);
    }
  };

  const confirmAddBrandType = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add this brand type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleAddBrandType();
        Swal.fire("Added!", "The brand type has been added.", "success");
      }
    });
  };

  const confirmAddBrand = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add this brand?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleAddBrand();
        Swal.fire("Added!", "The brand has been added.", "success");
      }
    });
  };

  const confirmDeleteBrandType = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this brand type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteBrandType(id);
        Swal.fire("Deleted!", "The brand type has been deleted.", "success");
      }
    });
  };

  const confirmDeleteBrand = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this brand?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteBrand(id);
        Swal.fire("Deleted!", "The brand has been deleted.", "success");
      }
    });
  };

  if (loading) {
    return (
      <div>
        <LoadingOverLay />
      </div>
    );
  }

  return (
    <>
      {loading && <LoadingOverLay />}
      <div className="pagetitle">
        <h1>Product Mix</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/setup/main">
                <span> Set Up</span>
              </Link>
            </li>
            <li className="breadcrumb-item active">Product Mix</li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div>
                <h5 className="card-title">Product Type</h5>

                <div className="input-group mb-3">
                  <input
                    name="brandTypeName"
                    value={form.brandTypeName}
                    onChange={handleInputChange}
                    placeholder="Product Type"
                    className="form-control"
                    aria-describedby="button-addon2"
                    required
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    id="button-addon2"
                    onClick={confirmAddBrandType}
                  >
                    Add
                  </button>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product Type</th>
                      <th className="d-none">Status</th>
                      <th className="d-none">CreateDate</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brandTypes.map((type, index) => (
                      <tr key={type.brandTypeID}>
                        <td>{index + 1}</td>
                        <td>{type.brandTypeName}</td>
                        <td className="d-none">
                          {type.brandTypeStatus == 1 ? "Active" : "Inactive"}
                        </td>
                        <td className="d-none">{type.brandTypeCreateDate}</td>
                        <td>
                          <button
                            className="btn btn-danger rounded-pill d-none"
                            onClick={() =>
                              confirmDeleteBrandType(type.brandTypeID)
                            }
                          >
                            Delete
                          </button>
                          <button
                            className={`btn btn-sm ${
                              type.brandTypeStatus
                                ? "btn-success"
                                : "btn-secondary"
                            } rounded-pill`}
                            onClick={() =>
                              handleToggleBrandTypeStatus(
                                type.brandTypeID,
                                type.brandTypeStatus
                              )
                            }
                          >
                            {type.brandTypeStatus ? "Active" : "Inactive"}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Product Brand</h5>

                <div className="input-group mb-3">
                  <select
                    className="form-control"
                    name="brandTypeID"
                    value={form.brandTypeID}
                    onChange={handleInputChange}
                  >
                    <option value=""> Product Type </option>
                    {brandTypes.map((type) => (
                      <option key={type.brandTypeID} value={type.brandTypeID}>
                        {type.brandTypeName}
                      </option>
                    ))}
                  </select>

                  <input
                    name="brandName"
                    value={form.brandName}
                    onChange={handleInputChange}
                    placeholder="Product Brand"
                    className="form-control"
                    aria-describedby="button-addon2"
                    required
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    id="button-addon2"
                    onClick={confirmAddBrand}
                  >
                    Add
                  </button>
                </div>

                <hr />

                <select
                  className="form-control"
                  name="brandTypesSelect"
                  value={form.brandTypeID}
                  onChange={handleInputChange}
                >
                  <option value="">Select Brand Type</option>
                  <option value="all"> ALL </option>
                  {brandTypes.map((type) => (
                    <option key={type.brandTypeID} value={type.brandTypeID}>
                      {type.brandTypeName}
                    </option>
                  ))}
                </select>

                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product Type </th>
                      <th>Product Brand</th>
                      <th className="d-none">Status</th>
                      <th className="d-none">CreateDate</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brands
                      .filter((brand) =>
                        form.brandTypesSelect === "all"
                          ? true
                          : brand.brandTypeID ===
                            parseInt(form.brandTypesSelect)
                      )
                      .map((brand, index) => (
                        <tr key={brand.brandID}>
                          <td>{index + 1}</td>
                          <td>{brand.brandTypeName}</td>
                          <td>{brand.brandName}</td>
                          <td className="d-none">
                            {brand.brandStatus === 1 ? "Active" : "Inactive"}
                          </td>
                          <td className="d-none">{brand.brandCreateDate}</td>
                          <td>
                            <button
                              className="btn btn-danger rounded-pill d-none"
                              onClick={() => confirmDeleteBrand(brand.brandID)}
                            >
                              Delete
                            </button>
                            <button
                              className={`btn btn-sm ${
                                brand.brandStatus
                                  ? "btn-success"
                                  : "btn-secondary"
                              } rounded-pill`}
                              onClick={() =>
                                handleToggleBrandStatus(
                                  brand.brandID,
                                  brand.brandStatus
                                )
                              }
                            >
                              {brand.brandStatus ? "Active" : "Inactive"}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductMix;
